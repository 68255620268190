<template>
  <div class="homepage-3">
    <ScrollupSection />
    <FacebookChat />
    <div class="main overflow-hidden">
      <transition name="fade" mode="out-in">
        <HeaderSection />
      </transition>
      <HeroSection />
      <PromoSection />
      <!-- <ContentOneSection /> -->
      <!-- <ContentFourSection /> -->
      <!-- <ServiceSection /> -->
      <!-- <PricingSection /> -->
      <!-- <ReviewSection /> -->
      <ContactSection />
      <!-- <CtaSection /> -->
      <FooterSection />
      <ModalSearchSection />
      <ModalMenuSection />
      <ModalRegistro />
    </div>
  </div>
</template>

<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import HeroSection from "../components/Hero/HeroThree";
import PromoSection from "../components/Promo/PromoTwo";
// import ContentOneSection from "../components/Content/ContentTwo";
/* import ContentFourSection from '../components/Content/ContentFour'
import ServiceSection from '../components/Services/ServiceThree'
import PricingSection from '../components/Pricing/PricingTwo'
import ReviewSection from '../components/Reviews/ReviewTwo' */
import ContactSection from "../components/Contact/ContactOne";
// import CtaSection from "../components/Cta/Cta";
import FooterSection from "../components/Footer/FooterOne";
import ModalSearchSection from "../components/Modal/ModalSearch/ModalSearch";
//import ModalRegistro from ;
import ModalMenuSection from "../components/Modal/ModalMenu/ModalMenu";
import FacebookChat from "../components/FacebookPlugin/FacebookChat"

export default {
  name: "ThemeThree",
  components: {
    ScrollupSection,
    HeaderSection,
    HeroSection,
    PromoSection,
    // ContentOneSection,
    /* ContentFourSection,
    ServiceSection,
    PricingSection,
    ReviewSection, */
    ContactSection,
    // CtaSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    FacebookChat,
    ModalRegistro : () => import("../components/Modal/ModalSearch/ModalRegistro"),
  },
};
</script>

<style>
</style>