import actions from './actions'
import mutations from './mutations'
import getters from './getters';

const state = {
    consulta: {},
    formatoDatos: {},
    medidasCautelares: '',
    medidasProteccion: '',
    descripcionAgravios: '',
    suscripciones: '',
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}