
<template>
  <section id="seccion-formatos" class="section promo-area ptb_100">
    <div class="container scrollNew">
      <div class="row">
        <div class="col-12 mb-3">
          <FormatosFree />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import FormatosPago from "../formatos/FormatosPago";
import FormatosFree from "../formatos/FormatosFree";

export default {
  components: {
    // FormatosPago,
    FormatosFree,
  },
  name: "PromoTwo",
  data() {
    return {
      activo: 1,
    };
  },
  methods: {
    setActive(item) {
      this.activo = item;
    },
  },
};
</script>

<style>
.tabItem {
  margin-bottom: 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  background: #dee2e6;
}



.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.9;
}
</style>