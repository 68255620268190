import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { auth } from './firebase'
import VueMeta from 'vue-meta'


Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(Vuelidate)
Vue.component('v-select', vSelect)
Vue.use(VueMeta)


auth.onAuthStateChanged(user => {
  if (user) {
    /* console.log('valid user', user)
    if (!user.emailVerified) {
        store.dispatch('auth/verfiyEmail');
    } */
    const usuario = JSON.parse(sessionStorage.getItem('usuario'));
    store.commit('auth/setUsuario', usuario);
  } else {
    console.log('invalid user ', user)
    //store.dispatch('detectarUsuario', user)
  }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
