import Vue from 'vue'
import Router from 'vue-router'
import ThemeThree from '@/themes/theme-three'
import { routes } from './formatosRoutes';
import { routesChat } from './ChatETRoutes';
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'inicio',
      component: ThemeThree,
      meta: { requiresAuth: false }
    },
    {
      path: '/chatet',
      name: 'chatet',
      component: () => import(/* webpackChunkName: "about" */ '@/components/InnerPages/ChatET/ChatET'),
      meta: { requiresAuth: false }
    },
    {
      path: '/about',
      name: 'About',
      component: () => import(/* webpackChunkName: "about" */ '@/components/InnerPages/About/About'),
      meta: { requiresAuth: false }
    },
    {
      path: '*',
      name: 'Error',
      component: () => import(/* webpackChunkName: "error" */ '@/components/InnerPages/Error/404'),
      meta: { requiresAuth: false }
    },
    {
      path: '/learning',
      name: 'learning',
      component: () => import(/* webpackChunkName: "learning" */ '@/components/Blogs/BlogTwoColumn/BlogTwoColumn'),
      meta: { requiresAuth: false }
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import(/* webpackChunkName: "blog" */ '@/components/Blogs/BlogThreeColumn/BlogThreeColumn'),
      meta: { requiresAuth: false }
    },
    {
      path: '/blog-suscribe',
      name: 'blogSuscribe',
      component: () => import(/* webpackChunkName: "blog" */ '@/components/Blogs/BlogThreeColumn/BlogsAd'),
      meta: { requiresAuth: false }
    },
    {
      path: '/post',
      name: 'blogDesc',
      component: () => import(/* webpackChunkName: "blogDesc" */ '@/components/Blogs/BlogThreeColumn/BlogDescription'),
      meta: { requiresAuth: false }
    },
    {
      path: '/marketplace',
      name: 'marketplace',
      component: () => import(/* webpackChunkName: "Services" */ '@/components/InnerPages/Services/Services'),
      meta: { requiresAuth: false }
    },
    {
      path: '/marketplace-ad',
      name: 'marketplace-ad',
      component: () => import(/* webpackChunkName: "Services" */ '@/components/InnerPages/Services/ServicesAd'),
      meta: { requiresAuth: false }
    },
    {
      path: '/perfil',
      name: 'perfil',
      component: () => import(/* webpackChunkName: "Perfil" */ '@/views/Perfil'),
      meta: { requiresAuth: true }
    },
    {
      path: '/solicitarFactura',
      name: 'solicitarFactura',
      component: () => import(/* webpackChunkName: "solicitarFactura" */ '@/views/solicitudFactura'),
      meta: { requiresAuth: false }
    },
    {
      path: '/Documents',
      name: 'Documents',
      component: () => import(/* webpackChunkName: "Documentos" */ '@/views/Documents'),
      props: true,
      meta: { requiresAuth: false }
    },
    {
      path: '/blog-post',
      name: 'blog-post',
      component: () => import(/* webpackChunkName: "Documentos" */ '@/views/BlogPost'),
      props: true,
      meta: { requiresAuth: false }
    },
    {
      path: '/privacidad',
      name: 'privacidad',
      component: () => import(/* webpackChunkName: "Privacidad" */ '@/views/Privacidad'),
      meta: { requiresAuth: false }
    },
    {
      path: '/terminos',
      name: 'terminos',
      component: () => import(/* webpackChunkName: "Terminos" */ '@/views/Terminos'),
      meta: { requiresAuth: false }
    },
    {
      path: '/datosPersonales',
      name: 'datosPersonales',
      component: () => import(/* webpackChunkName: "Terminos" */ '@/views/DatosPersonales'),
      meta: { requiresAuth: false }
    },
    {
      path: '/electoralSearch',
      name: 'electoralSearch',
      component: () => import(/* webpackChunkName: "Terminos" */ '@/components/InnerPages/ElectoralSearch/ElectoralSearch'),
      meta: { requiresAuth: false }
    },
    ...routes,
    ...routesChat,
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }; 
  },
  
});

router.beforeEach((to, from, next) => {
  const userLog = JSON.parse(sessionStorage.getItem('usuario'))
  // console.log({to, from, userLog, next})

  if (to.meta.requiresAuth && !userLog) {
    next({ name: 'inicio' })
  } else {
    next()
  }
})

export default router;