<template>
    <div id="scrollUp" title="Scroll To Top">
        <a class="fas fa-arrow-up" @click="scrollToTop()"></a>
    </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    scrollToTop() {
        window.scrollTo(0, 0);
    }
  }
  
}
</script>

<style>

</style>