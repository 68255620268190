export default {
    setUsuario(state, payload){
        state.usuarioLog = {...state.usuarioLog, ...payload}
        state.logIn = true;
        sessionStorage.setItem('usuario', JSON.stringify( state.usuarioLog))
    },
    logOut(state) {
        sessionStorage.removeItem('usuario');
        state.usuarioLog = {}
        state.logIn = false;
    }
}