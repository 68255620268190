export default {
    getrelacionesSocialM: (state) => {
        return state.relacionesSocial.filter(rs => {
            if (rs.genero == 2 || rs.genero == 0) {
                return { id: rs.id, texto: rs.texto }
            }
        })
    },
    getrelacionesSocialH: (state) => {
        return state.relacionesSocial.filter(rs => {
            if (rs.genero == 1 || rs.genero == 0) {
                return { id: rs.id, texto: rs.texto }
            }
        })
    },
    descripcionHechos: (state) => {
        switch (state.tipoFormato) {
            case 1:
                return state.textoHechosV;
            default:
                return state.textoHechos;
        }
    },
}