export default {
    setConsulta(state, payload) {
        state.consulta = payload;
    },
    setSuscripciones(state, payload) {
        state.suscripciones = payload;
    },
    setIniFormato(state) {
        state.formatoDatos = {}
        state.medidasCautelares = '';
        state.medidasProteccion = '';
        state.descripcionAgravios = '';
    },
    setFormatoDatos(state, payload) {
        state.formatoDatos = {
            ...state.formatoDatos,
            ...payload
        }
    },
    setDatosRepresentante(state, payload) {
        state.formatoDatos.representante = payload;
    },
    setHechos(state, payload) {
        state.formatoDatos.hechos = payload;
    },
    setUsertoFomart(state, payload) {
        state.formatoDatos.user = payload;
    },
    setMedidas(state, { tipoMedidas, medidas }) {
        state[tipoMedidas] = medidas;
    },
    setDemandadoData(state, payload) {
        state.formatoDatos.demandado = {
            ...payload
        }
    }
}