export default {
    getMedidasCautelares(state) {
        return state.medidasCautelares;
    },
    getMedidasProteccion(state) {
        return state.medidasProteccion;
    },
    getFormatoData(state) {
        return {
            ...state.formatoDatos,
            medidasCautelares: state.medidasCautelares,
            medidasProteccion: state.medidasProteccion,
            descripcionAgravios: state.descripcionAgravios
        }
    }
}