<template>
  <section id="contact" class="contact-area ptb_100" style="background: #f4f4f4">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-lg-5">
          <!-- Section Heading -->
          <div class="section-heading text-center mb-3">
            <h2>¡ Contáctanos !</h2>
            <p class="d-none d-sm-block mt-4">
              Estamos disponibles a través de nuestras redes sociales, correo electrónico, WhatsApp y vía telefónica al
              <b>228 307 8010</b> 
            </p>
          </div>
          <!-- Contact Us -->
          <div class="contact-us">
            <ul>
              <!-- Contact Info -->
              <!-- <li class="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3">
                                <span><i class="fas fa-mobile-alt fa-3x"></i></span>
                                <a class="d-block my-2" href="#">
                                    <h3 style="color:white">+89 (0) 2354 5470091</h3>
                                </a>
                                <p style="color:white">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </li> -->
              <!-- Contact Info -->
              <li
                  class="contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3"
              >
                <span><i class="fas fa-envelope-open-text fa-3x"></i></span>
                <a class="d-none d-sm-block my-2" href="mailto: contacto@electoraltech.com.mx">
                  <h4 style="color: white">contacto@electoraltech.com.mx</h4>
                </a>
                <a class="d-block d-sm-none my-2" href="mailto: contacto@electoraltech.com.mx">
                  <h4 style="color: white">contacto@electoraltech.com.mx</h4>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-4 pt-lg-0">
          <!-- Contact Box -->
          <div class="contact-box text-center">
            <!-- Contact Form -->
            <form @submit.prevent="submit">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model.trim="$v.form.nombre.$model"
                        :class="{ error: $v.form.nombre.$error }"
                        placeholder="Nombre"
                    />
                  </div>
                  <div class="form-group">
                    <input
                        type="email"
                        class="form-control"
                        name="email"
                        v-model.trim="$v.form.email.$model"
                        :class="{ error: $v.form.email.$error }"
                        placeholder="Email"
                    />
                  </div>
                  <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        name="subject"
                        v-model.trim="$v.form.telefono.$model"
                        :class="{ error: $v.form.telefono.$error }"
                        placeholder="Telefono"
                    />
                    <div style="text-align: left; color: #3B3B3B">
                      <small>Número de télefono a 10 dígitos, solo números.</small>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label style="color: #3B3B3B">¿Cómo prefieres que te contactemos?</label>
                  <br>
                  <div class="form-check form-check-inline" style="margin: 0 15px">
                    <input class="form-check-input" type="radio" v-model="form.preferencia" id="tel" value="1">
                    <label class="form-check-label" for="tel" style="color: #3B3B3B">
                      Teléfono
                    </label>
                  </div>
                  <div class="form-check form-check-inline" style="margin: 0 15px">
                    <input class="form-check-input" type="radio" id="emailOp" v-model="form.preferencia" value="2">
                    <label class="form-check-label" for="emailOp" style="color: #3B3B3B">
                      Email
                    </label>
                  </div>

                  <div class="form-check form-check-inline" style="margin: 0 15px">
                    <input class="form-check-input" type="radio" id="whats" v-model="form.preferencia" value="3">
                    <label class="form-check-label" for="whats" style="color: #3B3B3B">
                      WhatsApp
                    </label>
                  </div>
                  <div class="form-check form-check-inline" style="margin: 0 15px">
                    <input class="form-check-input" type="radio" id="chat" v-model="form.preferencia" value="4">
                    <label class="form-check-label" for="chat" style="color: #3B3B3B">
                      Chat
                    </label>
                  </div>

                </div>
                <div class="col-12">
                  <div class="form-group">
                    <textarea
                        class="form-control"
                        name="message"
                        v-model.trim="$v.form.comentario.$model"
                        :class="{ error: $v.form.comentario.$error }"
                        placeholder="¿En qué te podemos ayudar?"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12" v-if="!$v.$invalid">
                  <div class="form-group">
                    <vue-recaptcha
                        ref="recaptcha"
                        @verify="onVerify"
                        :sitekey="key"
                        @expired="onExpired"
                    >
                    </vue-recaptcha>
                  </div>
                </div>
                <div class="col-12">
                  <button
                      type="submit"
                      class="btn btn-bordered active btn-block mt-3"
                      :disabled="!captcha"
                  >
                    <span class="text-white pr-3"
                    ><i class="fas fa-paper-plane"></i></span
                    >Enviar mensaje
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import {mapState} from "vuex";
import {db, analytics} from "../../firebase";

export default {
  name: "Contact",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      form: {
        nombre: "",
        email: "",
        telefono: "",
        comentario: "",
        preferencia: null
      },
      captcha: false,
      urlProd:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
          //"http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
    };
  },
  validations: {
    form: {
      nombre: {required},
      email: {required, email},
      preferencia: {
        required
      },
      telefono: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      comentario: {required, maxLength: maxLength(100)},
    },
  },
  computed: {
    ...mapState("auth", ["usuarioLog", "logIn"]),
    ...mapState("generals", ["key"]),
    validateCaptcha() {
      return this.$v.$invalid && this.captcha;
    },
    url() {
      return process.env.NODE_ENV == "development"
          ? this.urlLocal
          : this.urlProd;
    },
  },
  methods: {
    onVerify: function (response) {
      if (response) {
        const url = process.env.NODE_ENV === "development" ? this.urlLocal : this.urlProd;
        fetch(url + "validateCaptcha/" + response)
            .then((res) => res.json())
            .then((res) => {
              console.info(res);
              this.captcha = true;
            })
            .catch((err) => {
              console.warn(err);
            });
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    async submit() {
      analytics.logEvent("Comentario");

      this.$swal({
        icon: "info",
        title: "Guardando comentario",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: true,
        didOpen: () => this.$swal.showLoading(),
      });
      // console.log(this.form);
      const comentData = {
        ...this.form,
        fechaRegistro: Date.now(),
        usuarioLogin: this.logIn ? this.usuarioLog : 0,
      };
      db.ref("comentarios")
          .push(comentData)
          .then((res) => {
            console.log(res);
            this.$swal.close();

            this.form.nombre = "";
            this.form.email = "";
            this.form.telefono = "";
            this.form.comentario = "";
            this.form.preferencia = "";
            this.$v.form.$reset();

            this.$swal({
              icon: "success",
              title:
                  "Gracias por comunicarte, nosotros nos pondremos en contacto contigo",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            });
            this.resetRecaptcha();
            this.captcha = false;
          })
          .catch((err) => {
            console.error(err);
            this.$swal({
              icon: "error",
              title: "Revisa tu conexión a internet e intenta de nuevo",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showCancelButton: true,
            });
          });
    },
  },
};
</script>

<style scoped>
.form-group .error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.contact-info{
  background: #150653;
}
</style>