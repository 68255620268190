import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/'
import generals from './generals/'
import contenido from './contenido/'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules:{
      auth,
      generals,
      contenido
  },
  strict: debug
})
