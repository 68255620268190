<template>
    <div id="test">
        <div class="fb-customerchat"
            attribution="install_email"
            page_id="103207585241232"
            theme_color="#7646ff"
            logged_in_greeting="Bienvenido a ElectoralTech, ¿Cómo Podemos Ayudarte?"
            logged_out_greeting="Bienvenido a ElectoralTech, ¿Cómo Podemos Ayudarte?">
            
        </div>      
    </div>
</template>

<script>
export default {
    
}
window.fbAsyncInit = function() {
  window.FB.init({
    xfbml            : true,
    version          : 'v10.0'
  });
};

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

</script>

<style scoped>

</style>