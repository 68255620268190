<template>
  <header>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.css">
    <!-- Navbar -->
    <nav class="navbar navbar-expand mb-4" id="barra-completa">
      <div class="container header">
        <!-- Navbar Brand  src="assets/eT/logo_white.png"   -->

        <a class="navbar-brand pushed-left">
          <img
            class="navbar-brand-regular"
            src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/OpenImages%2FLogos%2Flogo-animated-web.gif?alt=media&token=d16eae2c-b82d-4349-af6e-73fc1c8d4ed8" 
            style="height: 100px"
            alt="logo"
          />  
          <img
            class="navbar-brand-sticky"
            style="height: 100px"
            src="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/OpenImages%2FLogos%2FLogo_original.png?alt=media&token=a677c0ab-08af-4215-902b-011cd08b861c"
            alt="logo"
          />
        </a>
        <!--
        <div class="ml-auto"></div>
         Navbar -->
        <ul class="navbar-nav items ml-0 mr-0">
          <li class="nav-item">
            <router-link to="/">
              <a class="nav-link">Inicio</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/chatet">
              <a class="nav-link">ChatET</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/electoralSearch">
              <a class="nav-link">Electoral Search</a>
            </router-link>
          </li> 
          <li class="nav-item">
            <router-link to="/blog">
              <a class="nav-link"> Blog </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/learning">
              <a class="nav-link" >E-learning</a>
            </router-link>
          </li>
          
        
        </ul>

        <!-- Navbar Icons -->
        <div class="items-redes-login">
          <!-- Navbar Action Button -->
          <ul class="navbar-nav action pushed-right">
            <li class="nav-item ml-3" v-if="!logIn">
              <a
                class="btn ml-lg-auto btn-bordered-white"
                href="#"
                data-toggle="modal"
                data-target="#search"
              >
                <i class="fas fa-sign-in-alt"></i>
                Iniciar sesión
              </a>
            </li>
            <li class="nav-item ml-3" v-if="!logIn">
              <p>&nbsp;</p>
            </li>
            <li class="nav-item ml-3" v-if="!logIn">
              <a
                class="btn ml-lg-auto btn-bordered-white"
                href="#"
                data-toggle="modal"
                data-target="#registro"
              >
                <i class="fas fa-user-plus"></i>
                Registro
              </a>
            </li>
            <li class="nav-item ml-3" v-if="logIn">
              <div class="dropdown">
                <button
                  class="btn btn-bordered-white"
                  type="button"
                  id="dropdownMenu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Hola, {{ usuarioLog.nombre }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu">
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="cerrarSesion"
                  >
                    Cerrar Sesión
                  </button>
                  <button class="dropdown-item" type="button" @click="verPerfil">
                    Ver perfil
                  </button>
                  <!-- <button class="dropdown-item" type="button">
                    Something else here
                  </button> -->
                </div>
              </div>
            </li>
            <li class="nav-item ml-3" v-if="!logIn">
              <p>&nbsp;</p>
            </li>
            <li class="nav-item ml-3" v-if="logIn">
              <p>&nbsp;</p>
            </li>
            <li class="nav-item" v-if="!logIn">
              <a
                class="btn btn-download-app"
                target="_blank"
                @click="trackDownloadEvent"
              >
                <i class="fas fa-mobile-alt"></i>
                  Descargar App
              </a>
            </li>
            <li class="nav-item" v-if="logIn">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/electoraltech-d9cec.appspot.com/o/app%2Fapp-electoraltech.apk?alt=media&token=d107ac04-a428-4284-be13-5360778f209c"
                class="btn btn-download-app"
                target="_blank"
                @click="trackDownloadEvent"
              >
                <i class="fas fa-mobile-alt"></i>
                  Descargar App
              </a>
            </li>
          </ul>
          <ul class="navbar-nav icons pushed-right">

            <li class="nav-item social">
              <a
                href="https://web.facebook.com/ElectoralTech"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li class="nav-item social">
              <a
                href="https://twitter.com/Electoral_Tech"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li class="nav-item social">
              <a
                href="https://www.instagram.com/Electoral_Tech/"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
            <li class="nav-item social">
              <a
                href="https://www.youtube.com/@DrJoseOliveros"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
            <li class="nav-item social">
              <a
                href="https://bit.ly/3i4Udsk"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-whatsapp"></i>
              </a>
            </li>
            <li class="nav-item social">
              <a
                href="https://www.tiktok.com/@electoraltech"
                target="_blank"
                class="nav-link"
                ><i class='Tiktok fontawesomeicon'></i>
              </a>
            </li>
            <li class="nav-item social">
              <a
                href="https://t.me/ElectoralTech1"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-telegram"></i>
              </a>
            </li>
            <li class="nav-item social">
              <a
                href="https://www.linkedin.com/company/electoral-tech-legal/"
                target="_blank"
                class="nav-link"
                ><i class="fab fa-linkedin"></i></a>
            </li>
            </ul>

        </div>
                <!-- Navbar Toggler -->
                <ul class="navbar-nav toggle">
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                data-toggle="modal"
                data-target="#menu"
              >
                <i class="fas fa-bars toggle-icon m-0"></i>
              </a>
            </li>
          </ul>
          
      </div>
      
    </nav>
  </header>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { auth } from "../../firebase";
import { analytics} from "@/firebase.js";

export default {
  name: "headerComponent",
  mounted () {},
  data() {
    return {};
  },
  computed: {
    ...mapState("auth", ["usuarioLog", "logIn"]),
  },
  methods: {
    ...mapMutations({
      logOut: "auth/logOut",
    }),

    async trackDownloadEvent() {
      try{
        await this.alert()
        if (auth.currentUser != null){
          if (analytics) {
            analytics.logEvent("download_app", { name: "app-electoraltech" });
          }
        }
      
    } catch (error) {
        console.log(error);
      }
      
    },

    async alert() {
        if ( auth.currentUser == null ){
          const result = await this.$swal({
            icon: "info",
            title: "Registrate o Inicia sesión!",
            text: "Crea tu cuenta o inicia sesión para descargar la App de seguimiento de expedientes",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: true,
            confirmButtonText: 'Crear cuenta ahora',
          });
          if (result.isConfirmed) {
            window.$("#registro").modal('show');
          } else if (result.isDenied) {
            this.$router.push({ name: "inicio" });
            console.log('no confirmo')
          }
        }
      },

    async verPerfil() {
      this.$router.push({ name: 'perfil' }) 
    },
    async cerrarSesion() {
      try {
        await auth.signOut();
        this.logOut();
        sessionStorage.removeItem('usuario');
        if (this.$route.name == 'perfil') {
          this.$router.push({ name: 'inicio' });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.items-redes-login{
  display: flex;
    align-content: flex-start;
    justify-content: flex-end;
    max-width: 40%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.nav-icon{
  display: contents;
}

.nav

.pushed-right{
  margin-left: 0px!important;
  margin-right: 0px!important;
}

.container.header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.pushed-left{
  margin-left: 20px!important;
  margin-right: 0px!important;
}
@media (min-width: 1260px) {
  .pushed-right {
    margin-left: 0px!important;
    margin-right: -90px!important;
  }
  .pushed-left {
    margin-left: -90px!important;
    margin-right: 0px!important;
  }
}

.fontawesomeicon::before {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.Tiktok::before {
 font: var(--fa-font-brands);
  content: ' \e07b';
}

.nav-item {
  margin-left: 0px!important;
  margin-right: 0px!important;
  
}

.nav-item2 {
  margin-left: 0px!important;
  margin-right: 3px;
  
}

.btn.btn-bordered-whi:after {
  position: absolute;
  content: "";
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  top: -2px;
  left: -2px;
  border-radius: 100px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}


</style>
