import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

const state = {
    key: "6LczKqcaAAAAAISfCEMACn2088IZ-RhQtJcT2KSA",
    generosData: [
        {
            id: 1,
            label: "Hombre",
        },
        {
            id: 2,
            label: "Mujer",
        },
        {
            id: 3,
            label: "Prefiero no decir",
        },
        {
            id: 4,
            label: "Otro",
        },
    ],
    bookSelected: 0,
    tipoDocData: [
        {
            id: 1,
            label: 'Credencial para votar'
        },
        {
            id: 2,
            label: 'Pasaporte'
        },
        {
            id: 3,
            label: 'Cédula profesional'
        }
    ],
    motivosAgravio: [
        { id: 1, texto: 'I. Incumplir las disposiciones jurídicas nacionales e internacionales que reconocen el ejercicio pleno de los derechos políticos de las mujeres;' },
        { id: 2, texto: 'II. Restringir o anular el derecho al voto libre y secreto de las mujeres, u obstaculizar sus derechos de asociación y aﬁliación a todo tipo de organizaciones políticas y civiles, en razón de género;' },
        { id: 3, texto: 'III. Ocultar información u omitir la convocatoria para el registro de candidaturas o para cualquier otra actividad que implique la toma de decisiones en el desarrollo de sus funciones y actividades; ' },
        { id: 4, texto: 'IV. Proporcionar a las mujeres que aspiran u ocupan un cargo de elección popular información falsa o incompleta, que impida su registro como candidata o induzca al incorrecto ejercicio de sus atribuciones;' },
        { id: 5, texto: 'V. Proporcionar información incompleta o datos falsos a las autoridades administrativas, electorales o jurisdiccionales, con la ﬁnalidad de menoscabar los derechos políticos de las mujeres y la garantía del debido proceso;' },
        { id: 6, texto: 'VI. Proporcionar a las mujeres que ocupan un cargo de elección popular, información falsa, incompleta o imprecisa, para impedir que induzca al incorrecto ejercicio de sus atribuciones;' },
        { id: 7, texto: 'VII. Obstaculizar la campaña de modo que se impida que la competencia electoral se desarrolle en condiciones de igualdad;' },
        { id: 8, texto: 'VIII. Realizar o distribuir propaganda política o electoral que calumnie, degrade o descaliﬁque a una candidata basándose en estereotipos de género que reproduzcan relaciones de dominación, desigualdad o discriminación contra las mujeres, con el objetivo de menoscabar su imagen pública o limitar sus derechos políticos y electorales;' },
        { id: 9, texto: 'IX. Difamar, calumniar, injuriar o realizar cualquier expresión que denigre o descaliﬁque a las mujeres en ejercicio de sus funciones políticas, con base en estereotipos de género, con el objetivo o el resultado de menoscabar su imagen pública o limitar o anular sus derechos;' },
        { id: 10, texto: 'X. Divulgar imágenes, mensajes o información privada de una mujer candidata o en funciones, por cualquier medio físico o virtual, con el propósito de desacreditarla, difamarla, denigrarla y poner en entredicho su capacidad o habilidades para la política, con base en estereotipos de género;' },
        { id: 11, texto: 'XI. Amenazar o intimidar a una o varias mujeres o a su familia o colaboradores con el objeto de inducir su renuncia a la candidatura o al cargo para el que fue electa o designada;' },
        { id: 12, texto: 'XII. Impedir, por cualquier medio, que las mujeres electas o designadas a cualquier puesto o encargo público tomen protesta de su encargo, asistan a las sesiones ordinarias o extraordinarias o a cualquier otra actividad que implique la toma de decisiones y el ejercicio del cargo, impidiendo o suprimiendo su derecho a voz y voto;' },
        { id: 13, texto: 'XIII. Restringir los derechos políticos de las mujeres con base a la aplicación de tradiciones, costumbres o sistemas normativos internos o propios, que sean violatorios de los derechos humanos;' },
        { id: 14, texto: 'XIV. Imponer, con base en estereotipos de género, la realización de actividades distintas a las atribuciones propias de la representación política, cargo o función;' },
        { id: 15, texto: 'XV. Discriminar a la mujer en el ejercicio de sus derechos políticos por encontrarse en estado de embarazo, parto, puerperio, o impedir o restringir su reincorporación al cargo tras hacer uso de la licencia de maternidad o de cualquier otra licencia contemplada en la normatividad; XVI. Ejercer violencia física, sexual, simbólica, psicológica, económica o patrimonial contra una mujer en ejercicio de sus derechos políticos;' },
        { id: 16, texto: 'XVII. Limitar o negar arbitrariamente el uso de cualquier recurso o atribución inherente al cargo que ocupe la mujer, incluido el pago de salarios, dietas u otras prestaciones asociadas al ejercicio del cargo, en condiciones de igualdad;' },
        { id: 17, texto: 'XVIII. Obligar a una mujer, mediante fuerza, presión o intimidación, a suscribir documentos o avalar decisiones contrarias a su voluntad o a la ley;' },
        { id: 18, texto: 'XIX. Obstaculizar o impedir el acceso a la justicia de las mujeres para proteger sus derechos políticos;' },
        { id: 19, texto: 'XX. Limitar o negar arbitrariamente el uso de cualquier recurso o atribución inherente al cargo político que ocupa la mujer, impidiendo el ejercicio del cargo en condiciones de igualdad;' },
        { id: 20, texto: 'XXI. Imponer sanciones injustiﬁcadas o abusivas, impidiendo o restringiendo el ejercicio de sus derechos políticos en condiciones de igualdad;' },
        { id: 21, texto: 'XXII. Otras formas análogas que lesionen o sean susceptibles de dañar la dignidad, integridad o libertad de las mujeres en el ejercicio de un cargo político, público, de poder o de decisión, que afecte sus derechos políticos electorales.' },
    ],
    medidasProteccion: [
        {id: 0, texto: "I.	Prohibición de acercarse a determinada distancia de la presunta víctima;"},
        {id: 1, texto: "II.	Prohibición de comunicarse con la víctima;"},
        {id: 2, texto: "III.	Limitación para asistir o acercarse al domicilio de la víctima o lugar determinado;"},
        {id: 3, texto: "IV.	La entrega inmediata de objetos de uso personal y documentos de identidad de la víctima que tuviera en su posesión el probable responsable;"},
        {id: 4, texto: "V.	La prohibición de realizar conductas de intimidación o molestia a la víctima o a personas relacionadas con ella;"},
        {id: 5, texto: "VI.	 Protección policial de la víctima o de su domicilio;"},
        {id: 6, texto: "VII.	Auxilio inmediato por integrantes de instituciones policiales, al domicilio en donde se encuentre la víctima en el momento de solicitarlo; y"},
        {id: 7, texto: "VIII.	Traslado de la víctima a refugios o albergues temporales, así como de sus familiares."},
    ],
    pruebas: [
        {
            id: 1,
            texto: 'Fotos'
        },
        {
            id: 2,
            texto: 'Videos'
        },
        {
            id: 3,
            texto: 'Documentales públicas'
        },
        {
            id: 4,
            texto: 'Documentales privadas'
        },
        {
            id: 5,
            texto: 'Pericial'
        },
        {
            id: 6,
            texto: 'Inspección ocular'
        },
        {
            id: 7,
            texto: 'Audios'
        },
        {
            id: 8,
            texto: 'Confesional'
        },
        {
            id: 9,
            texto: 'Testimonial'
        },
        {
            id: 10,
            texto: 'Presuncional legal y humana'
        },
        {
            id: 11,
            texto: 'Instrumental de actuaciones'
        },
    ],
    institucionesJusticia: [
        {
            id: 1,
            texto: 'Secretaria General de Gobierno'
        },
        {
            id: 2,
            texto: 'Secretaria de Seguridad Pública'
        },
        {
            id: 3,
            texto: 'Fiscalía General del Estado'
        },
        {
            id: 4,
            texto: 'CNDH'
        },
        {
            id: 5,
            texto: 'Comisión Estatal de Derecho Humanos'
        },
        {
            id: 6,
            texto: 'Instituto Estatal de las Mujeres'
        },
        {
            id: 7,
            texto: 'Instituto Nacional Indigenista'
        },
        {
            id: 8,
            texto: 'INMUJERES'
        },
        {
            id: 9,
            texto: 'INE'
        },
        {
            id: 10,
            texto: 'OPLE'
        }
    ],
    cargosEleccionPopular: [
        { id: 1, texto: "Alcalde/Alcaldesa" },
        { id: 2, texto: "Consejal" },
        { id: 3, texto: "Gobernador/Gobernadora" },
        { id: 4, texto: "Diputado/Diputada" },
        { id: 5, texto: "Presidente/Presidenta Municipal" },
        { id: 6, texto: "Síndico/Síndica" },
        { id: 7, texto: "Regidoro/Regidora" },
        { id: 8, texto: "Autoridad auxiliar del cabildo" }
    ],
    cargosEleccionPopularGnerales: [
        { id: 1, texto: "Alcalde/Alcaldesa" },
        { id: 2, texto: "Consejal" },
        { id: 3, texto: "Gobernador/Gobernadora" },
        { id: 4, texto: "Diputado/Diputada" },
        { id: 5, texto: "Presidente/Presidenta Municipal" },
        { id: 6, texto: "Síndico/Síndica" },
        { id: 7, texto: "Regidoro/Regidora" },
        { id: 8, texto: "Autoridad auxiliar del cabildo" }
    ],
    organosJurisdiccionales: [
        { id: 1, texto: "Local" },
        { id: 2, texto: "Federal" }
    ],
    relacionesSocial: [
        { id: 0, texto: 'Ninguno', genero: 0 },
        { id: 1, texto: 'Esposo', genero: 1 },
        { id: 2, texto: 'Esposa', genero: 2 },
        { id: 3, texto: 'Padre', genero: 1 },
        { id: 4, texto: 'Madre', genero: 2 },
        { id: 5, texto: 'Hijo', genero: 1 },
        { id: 6, texto: 'Hija', genero: 2 },
        { id: 7, texto: 'Hermano', genero: 1 },
        { id: 8, texto: 'Hermana', genero: 2 },
        { id: 9, texto: 'Primo', genero: 1 },
        { id: 10, texto: 'Prima', genero: 2 },
        { id: 11, texto: 'Sobrino', genero: 1 },
        { id: 12, texto: 'Sobrina', genero: 2 },
        { id: 13, texto: 'Nieto', genero: 1 },
        { id: 14, texto: 'Nieta', genero: 2 },
        { id: 15, texto: 'Abuelo', genero: 1 },
        { id: 16, texto: 'Abuela', genero: 2 },
        { id: 17, texto: 'Familiar', genero: 0 },
        { id: 18, texto: 'Amigo', genero: 1 },
        { id: 18, texto: 'Amiga', genero: 2 },
        { id: 19, texto: 'Colega', genero: 0 },
        { id: 20, texto: 'Cliente', genero: 0 },
    ],
    documentosRepresentacion: [
        { id: 1, texto: 'Poder General' },
        { id: 2, texto: 'Carta poder ratiﬁcada' },
        { id: 3, texto: 'Mandato especial' },
    ],
    estados: [],
    ciudades: [],
    textoHechosV: `Realiza una narración clara de los hechos que
    se consideran constitutivos de violencia política por razón de género,
    señalando circunstancias de modo (cómo sucedió), tiempo (cuándo
    sucedió) y lugar (en dónde sucedió)`,
    textoHechos: `Realiza una narración clara de los hechos 
    señalando circunstancias de modo (cómo sucedió), tiempo (cuándo
    sucedió) y lugar (en dónde sucedió)`,
    tipoFormato: 0,
    voucherBand: false,
    idConsultaVoucher: '',
    voucherVisualizacion:{
        fecha: '',
        url: ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}