export const routesChat = [
    {
        path: '/propagandaProhibida',
        name: 'PropagandaProhibida',
        component: () => import(/* webpackChunkName: "ViolenciaGenero" */ '@/components/formatsChatET/PropagandaProhibida'),
        meta: { requiresAuth: false }
    },
    {
        path: '/fiscalizacion',
        name: 'Fiscalizacion',
        component: () => import(/* webpackChunkName: "Fiscalizacion" */ '@/components/formatsChatET/Fiscalizacion'),
        meta: { requiresAuth: false }
    }, 
    {
        path: '/accionesAfirmativas',
        name: 'AccionesAfirmativas',
        component: () => import(/* webpackChunkName: "AccionesAfirmativas" */ '@/components/formatsChatET/AccionesAfirmativas'),
        meta: { requiresAuth: false }
    },
    {
        path: '/actosParlamentarios',
        name: 'ActosParlamentarios',
        component: () => import(/* webpackChunkName: "ActosParlamentarios" */ '@/components/formatsChatET/ActosParlamentarios'),
        meta: { requiresAuth: false }
    },
    {
        path: '/paridadGenero',
        name: 'ParidadGenero',
        component: () => import(/* webpackChunkName: "ParidadGenero" */ '@/components/formatsChatET/ParidadGenero'),
        meta: { requiresAuth: false }
    },
    {
        path: '/tiempoRadioTV',
        name: 'TiempoRadioTV',
        component: () => import(/* webpackChunkName: "juicioDeRevision" */ '@/components/formatsChatET/TiempoRadioTV'),
        meta: { requiresAuth: false }
    },
    {
        path: '/actosAnticipadosCampaña',
        name: 'ActosAnticipadosCampaña',
        component: () => import(/* webpackChunkName: "promoJDC" */ '@/components/formatsChatET/ActosAnticipadosCampaña'),
        meta: { requiresAuth: false }
    },
    // {
    //     path: '/actosVinculadosProcesoElectoralVotoExtranjero',
    //     name: 'ActosVinculadosProcesoElectoralVotoExtranjero',
    //     component: () => import(/* webpackChunkName: "jdcCredencial" */ '@/components/formatsChatET/ActosVinculadosProcesoElectoralVotoExtranjero'),
    //     meta: { requiresAuth: false }
    // },
    {
        path: '/coaccionVoto',
        name: 'CoaccionVoto',
        component: () => import(/* webpackChunkName: "recursoRIN" */ '@/components/formatsChatET/CoaccionVoto'),
        meta: { requiresAuth: false }
    },
    {
        path: '/democraciaIntrapartidista',
        name: 'DemocraciaIntrapartidista',
        component: () => import(/* webpackChunkName: "PromoRIN" */ '@/components/formatsChatET/DemocraciaIntrapartidista'),
        meta: { requiresAuth: false }
    },
    {
        path: '/designacionMagistraturasIntegracionOrganosElectorales',
        name: 'DesignacionMagistraturasIntegracionOrganosElectorales',
        component: () => import(/* webpackChunkName: "JuicioRIN" */ '@/components/formatsChatET/DesignacionMagistraturasIntegracionOrganosElectorales'),
        meta: { requiresAuth: false }
    },
    {
        path: '/validezEleccion',
        name: 'ValidezEleccion',
        component: () => import(/* webpackChunkName: "tercerInteresado" */ '@/components/formatsChatET/ValidezEleccion'),
        meta: { requiresAuth: false }
    },
    {
        path: '/violenciaPoliticaGenero',
        name: 'ViolenciaPoliticaGenero',
        component: () => import(/* webpackChunkName: "recursoREP" */ '@/components/formatsChatET/ViolenciaPoliticaGenero'),
        meta: { requiresAuth: false }
    },
    
];