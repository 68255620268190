export default {
    setStaes(state, payload){
        state.estados = payload;
    },
    setMunicipios(state, payload){
        state.ciudades = payload;
    },
    setTipoFormato(state, payload){
        state.tipoFormato = payload;
    },
    setVoucherBand(state, { band, id }){
        state.voucherBand = band;
        state.idConsultaVoucher = id;
    },
    setInfoVoucherVisualizacion(state, payload){
        state.voucherVisualizacion = payload;
    }
}