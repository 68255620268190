<template>
  <div class="modal" :class="{ 'is-active': isActive }" @click="closeModalOutside">
    <div class="modal-background"></div>
    <div class="modal-card" @click.stop>
      <header class="modal-card-head">
        <p class="modal-card-title">¿Cuál formato necesitas?</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <table class="table is-fullwidth">
          <tbody>
            <tr v-for="(formato, index) in formatosForm" :key="index" 
                :class="{ 'is-white': index % 2 === 0, 'is-gray': index % 2 !== 0 }"
                @click="openLink(formato.link)">
              <td style="cursor: pointer;">
                <a>{{ formato.nombre }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalFormats',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    formatosForm: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    closeModalOutside(event) {
      if (event.target === event.currentTarget) {
        this.closeModal();
      }
    },
    openLink(link) {
      window.open(link, '_blank');
    }
  }
};
</script>


<style scoped>
.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-card {
  width: 600px; /* Adjust the width as needed */
  max-height: 70vh; /* Ensure it does not overflow the viewport height */
  overflow-y: auto; /* Scroll if content exceeds max height */
  background: white;
  border-radius: 8px;
  position: relative;
}

.modal-card-head {
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.modal-card-head .modal-card-title {
  font-size: 1.25rem;
  margin: 0;
}

.modal-card-head .delete {
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-background {
  background: rgba(10, 10, 10, 0.915); /* Darken the background */
}

.table.is-fullwidth {
  width: 100%;
}

.table td {
  padding: 10px;
  cursor: pointer;
}

.table td a {
  font-size: 16px;
}

.is-white { background-color: #ffffff; }
.is-gray { background-color: #f0f0f0; }
</style>




  