<template>
  <section
    id="home"
    class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center"
  >
    <div class="container headerTitle">
      <div class="row align-items-center">
        <!-- Welcome Intro Start -->
        <div class="col-12 col-md-6">
          <div class="welcome-intro">
            <h1 class="text-white text-center text-md-left pushed-down">
              Crea gratuitamente <br />
              <span class="fw-3" style="font-size: 3.1rem">
                tu demanda o petición en materia electoral</span
              >
            </h1>
            <p class="text-white text-center text-md-left my-4">
              Deja que la tecnología haga el trabajo por ti. Además, obtén orientación adecuada en línea con un abogado experimentado en materia electoral.
            </p>
            <!-- Buttons -->
            <div class="button-group d-flex align-items-center justify-content-center justify-content-md-start">
              <a @click="showModal = true" class="btn btn-bordered-white" id="btn-crear-formato">
                ¿CUÁL FORMATO NECESITAS?</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <!-- Welcome Thumb -->
          <div class="welcome-thumb-wrapper pt-3 pt-md-5 mt-5">
            <span class="welcome-thumb-1">
              <a :href="linkTo">
                <img
                  id="img-home"
                  class="welcome-animation d-block ml-auto"
                  src="assets/eT/generando_formato.png"
                  alt="Imagen de generación de formato"
                  width="500px"
                />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Shape Bottom -->
    <div class="welcome-shape">
      <img src="assets/img/hero_shape.png" alt="Imagen de fondo de asesoria legal" />
    </div>
    <ModalFormats :isActive="showModal" :formatosForm="formatosForm" @close="showModal = false" />
  </section>
</template>

<script>
import ModalFormats from '../Modal/ModalFormats/ModalFormats.vue';

import { fr } from "../../firebase";

export default {
  name: "HeroThree",
  components: {
    ModalFormats
  },
  data() {
    return {
      tempImgSrc: [],
      linkTo: "#seccion-formatos",
      recentPost: [
        { src: "assets/eT/generando_formato.png", link: "#seccion-formatos" },
      ],
      showModal: false,
      formatosForm: [
        { nombre: 'PES ART 134 CF', link: 'https://forms.gle/pe1ptZAkfhbAoKny8' },
        { nombre: 'JDC VS VIODEF', link: 'https://forms.gle/7vGtUoWZtPZemECq6' },
        { nombre: 'JIN VS ELECCPTE', link: 'https://forms.gle/kUAZmfJQ8KkKiyP36' },
        { nombre: 'JIN SENADOR DIPMR', link: 'https://forms.gle/k3CirNCyknLv3W8MA' },
        { nombre: 'JDC VS INTEGRAC ORG', link: 'https://forms.gle/HUazi3rcpUMoDnsk7' },
        { nombre: 'REP VS ESPECIAL', link: 'https://forms.gle/LfG9izAQiWTsYvD16' },
        { nombre: 'REC VS SENT FONDO CASILLAS', link: 'https://forms.gle/fJqEKKSeYAhPiBFu6' },
        { nombre: 'REC VS RSPINE', link: 'https://forms.gle/cEMt7ZbsnsQdjV7R6' },
        { nombre: 'REC VS JINSREG', link: 'https://forms.gle/NEabGtmErnyesFxTA' },
        { nombre: 'REC SRG NO APLICACIÓN', link: 'https://forms.gle/QECmFCZBjqoGzbnF8' },
        { nombre: 'RAP VS UTFLIQPP', link: 'https://forms.gle/yuEWU8MCrtvWbCQ98' },
        { nombre: 'RAP VS RRVINE', link: 'https://forms.gle/RwUuC3K6QFrVTXe19' },
        { nombre: 'RECURSO DE APELACIÓN (RAP) CONTRA ACTOS DE ÓRGANOS DEL INE', link: 'https://forms.gle/NU33WZy8LNtP3KQi8' },
        { nombre: 'JRC VS ELECCLOCALES', link: 'https://forms.gle/yb1EpxTcnS4wLAp77' },
        { nombre: 'RAP CONTRA EL INFORME DE LA DERFE A LA CNV Y AL CG DEL INE', link: 'https://forms.gle/WaHznHyi5KcbAY357' },
        { nombre: 'JDC POR AFECTACIÓN', link: 'https://forms.gle/6S7ohWnt9uAHY7Nc7' },
        { nombre: 'AUDIENCIA DE PRUEBAS PES', link: 'https://forms.gle/2ES3yVG8f9UFr8tq9' },
        { nombre: 'MEDIDAS VPG', link: 'https://forms.gle/1SjEEmWsy5hDV1PC8' },
      ]
    }
  },
  methods: {
    scrollToElement() {
      const el = document.getElementsByClassName('scrollNew')[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async obtenerPostRecientes() {
      await fr.collection("blog").orderBy("fechaRegistro", 'desc')
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(resultado => {
            let appObj = resultado.data();
            let imgSrc = { ['src']: appObj.image, ['link']: "https://electoraltech.com.mx/blog" }
            this.tempImgSrc.push(imgSrc);
          });
        });

      for (let i = 0; i < 3; i++) {
        this.recentPost.push(this.tempImgSrc[i]);
      }

      this.tempImgSrc = [];

      await fr.collection("learning").orderBy("fechaRegistro", 'desc')
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(resultado => {
            let appObj = resultado.data();
            let imgSrc = { ['src']: appObj.image, ['link']: "https://electoraltech.com.mx/learning" }
            this.tempImgSrc.push(imgSrc);
          });
        });

      for (let i = 0; i < 2; i++) {
        this.recentPost.push(this.tempImgSrc[i]);
      }
      console.log(this.recentPost);
    },
    setNewImage() {
      if (this.recentPost.length > 0) {
        let id = Math.floor(Math.random() * this.recentPost.length);
        let imgElement = document.getElementById("img-home");
        if (imgElement) {
          console.log(this.recentPost[id].src);
          this.linkTo = this.recentPost[id].link;
          imgElement.src = this.recentPost[id].src;
        } else {
          console.error("Element with id 'img-home' not found.");
        }
      } else {
        console.error("recentPost array is empty.");
      }
    },
    goTo() {
      window.location.href = this.link;
    }
  },
  mounted() {
    this.obtenerPostRecientes().then(() => {
      this.interval = setInterval(() => this.setNewImage(), 3000);
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
