<template>
  <div class="lista-twitter" id="encabezados-lista-twitter" >
    <div class="row-lista-items" id="lista-items">
      <div class="encabezados" id="izquierda">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          
          <li class="nav-item" role="presentation">
            <a class="nav-link-free active" id="pills-vgp-tab" data-toggle="pill" href="#pills-vgp" role="tab"
               aria-controls="pills-vgp" aria-selected="true">
              Juicio Ciudadano (JDC-VPG)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-free" id="pills-jdc-cpvf-tab" data-toggle="pill" href="#pills-jdc-cpvf" role="tab"
               aria-controls="pills-jdc-cpvf" aria-selected="false">
              Juicio Ciudadano (JDC-CPVF)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-free" id="pills-jdcAF-tab" data-toggle="pill" href="#pills-jdcAF" role="tab"
               aria-controls="pills-jdcAF" aria-selected="false">
               Acción Afirmativa Migrante (JDC)
            </a>
          </li> 
          <li class="nav-item" role="presentation">
            <a class="nav-link-free" id="pills-pes-tab" data-toggle="pill" href="#pills-pes" role="tab" aria-controls="pills-pes"
               aria-selected="false">
              Procedimiento Especial Sancionador (PES-VPG)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-jrc-tab" data-toggle="pill" href="#pills-rap" role="tab"
               aria-controls="pills-jrc" aria-selected="false">
              Recurso de Apelación (RAP)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-dp-tab" data-toggle="pill" href="#pills-dp" role="tab"
               aria-controls="pills-dp" aria-selected="false">
              Derecho de Petición (DP)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-aux-tab" data-toggle="pill" href="#pills-aux" role="tab"
               aria-controls="pills-aux" aria-selected="false">
              Remuneración de Autoridad Auxiliar (RAA)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-jrc-tab" data-toggle="pill" href="#pills-jrc" role="tab"
               aria-controls="pills-jrc" aria-selected="false">
              Juicio de Revision Constitucional (JRC)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-rin-tab" data-toggle="pill" href="#pills-rin" role="tab"
               aria-controls="pills-rin" aria-selected="false">
               Incorformidad (RIN,JIN)
            </a>
          </li>

          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-rep-tab" data-toggle="pill" href="#pills-rep" role="tab"
               aria-controls="pills-rep" aria-selected="false">
               Recurso de Revisión de Procedimiento Especial Sancionador(REP)
            </a>
          </li>
          <!--
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-oic-tab" data-toggle="pill" href="#pills-rep" role="tab"
               aria-controls="pills-rep" aria-selected="false">
               Procedimiento de Responsabilidad Administrativa(INE) (OIC)
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link-cost" id="pills-oic-tab" data-toggle="pill" href="#pills-rep" role="tab"
               aria-controls="pills-rep" aria-selected="false">
               Juicio Laboral Electoral(INE) (JLI)
            </a>
          </li>-->
          <!--
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-tercerintere-tab" data-toggle="pill" href="#pills-tercerintere" role="tab"
               aria-controls="pills-tercerintere" aria-selected="false">
               Tercer Interesado
            </a>
          </li>
          -->  



        </ul>
      </div>
      
      <div class="tab-content" id="pills-tabContent">
        <!--
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home">
          <div class="row-items">
            <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(-1)" :key="f.id">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper" @click="verForm(f.tipo)">
                  <h3 class="mb-3">{{ f.nombre }}</h3>
                  <p>{{ f.descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home">
          <div class="row-items">
            <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(1)" :key="f.id">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper" @click="verForm(f.tipo)">
                  <h3 class="mb-3">{{ f.nombre }}</h3>
                  <p>{{ f.descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <div class="tab-pane fade" id="pills-vgp" role="tabpanel" aria-labelledby="pills-vgp">
          <div class="row-items">
            <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(1)" :key="f.id">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper" @click="verForm(f.tipo)">
                  <h3 class="mb-3">{{ f.nombre }}</h3>
                  <p>{{ f.descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-jdc-cpvf" role="tabpanel" aria-labelledby="pills-jdc-cpvf">
          <div class="row-items">
            <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(7)" :key="f.id">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper" @click="verForm(f.tipo)">
                  <h3 class="mb-3">{{ f.nombre }}</h3>
                  <p>{{ f.descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-pes" role="tabpanel" aria-labelledby="pills-pes">
          <div class="row-items">
            <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(2)" :key="f.id" style="width=100%">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper" @click="verForm(f.tipo)">
                  <h3 class="mb-3">{{ f.nombre }}</h3>
                  <p>{{ f.descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-dp" role="tabpanel" aria-labelledby="pills-dp">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(3)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3">{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-rap" role="tabpanel" aria-labelledby="pills-rap">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(6)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3">{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-aux" role="tabpanel" aria-labelledby="pills-aux">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(4)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3">{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-jrc" role="tabpanel" aria-labelledby="pills-jrc">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(5)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3">{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-rin" role="tabpanel" aria-labelledby="pills-rin">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(8)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3" >{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane fade" id="pills-rep" role="tabpanel" aria-labelledby="pills-rep">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(11)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3" >{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!--
        <div class="tab-pane fade" id="pills-tercerintere" role="tabpanel" aria-labelledby="pills-tercerintere">
          <div class="row">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(10)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3">{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        -->
        <div class="tab-pane fade" id="pills-jdcAF" role="tabpanel" aria-labelledby="pills-jdcAF">
          <div class="row-items">
          <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(12)" :key="f.id">
            <div class="single-promo grad-hover text-center p-5">
              <div class="promo-wrapper" @click="verForm(f.tipo)">
                <h3 class="mb-3" >{{ f.nombre }}</h3>
                <p>{{ f.descripcion }}</p>
              </div>
            </div>
          </div>
          </div>
        </div>

          <div class="tab-pane fade" id="pills-recursoRAP" role="tabpanel" aria-labelledby="pills-recursoRAP">
            <div class="row-items">
            <div class="col-12 col-md-6 col-lg-4" v-for="f in formatosPorGrupo(13)" :key="f.id">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper" @click="verForm(f.tipo)">
                  <h3 class="mb-3" >{{ f.nombre }}</h3>
                  <p>{{ f.descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="newsletter-twitter">
        <div class="newsletter">
            <input type="text" class="form-control form-settings" id="correoSuscribir" placeholder="correo@ejemplo.com" v-model="correoSus">
        </div>
        <div class="suscribirse"> 
          <button class="btn" type="button" @click="saveCorreos()" onclick="document.getElementById('correoSuscribir').value= ''">
           Suscribirse al Newsletter
          </button>
        </div>
      <div class="embebed-twitter" id="embebed-twitter-id">
          <a class="twitter-timeline" data-width="350" data-height="600" href="https://twitter.com/Electoral_Tech?ref_src=twsrc%5Etfw">Tweets by Electoral_Tech</a> 
        </div>
      </div>
  </div>
</template>

<script>
import {fr} from "../../firebase";
var TwitterWidgetsLoader = require('twitter-widgets');
export default {
  mounted() {
  TwitterWidgetsLoader.load();
 },
  name: "FormatosPago",
  data() {
    return {
      formatos: [
        {
          id: 0,
          nombre: "Violencia política en razón de género. Juicio cuidadano(JDC-VPG)",
          descripcion: "¿Requieres de la emisión de medidas cautelares o de protección por parte del INE? Genera tu propia solicitud digital de una manera sencilla y rápida.",
          tipo: 1,
          grupo: 1,
        },
        {
          id: 1,
          nombre: "Denuncia en materia de Violencia Política contra las mujeres en razón de género. Procedimiento Especial Sancionador(PES-VPG)",
          descripcion: "¿Necesitas denunciar un acto de violencia política en razón de género? Olvídate de pagar honorarios y hazlo tú misma.",
          tipo: 2,
          grupo: 2,
        },
        {
          id: 2,
          nombre: "Derecho de petición (DP) ",
          descripcion: "¿Formulaste una solicitud de información y de expedición de copias\n" +
              "              certificadas sin que exista respuesta? Crea tu propia demanda para\n" +
              "              exigir la entrega completa, congruente y oportuna.",
          tipo: 3,
          grupo: 3,
        },
        {
          id: 3,
          nombre: "Apelación (RAP)",
          descripcion: "¿Te ha sido impuesta una sanción por infracciones electorales?\n" +
              "              ¿Garantizaron tu derecho de audiencia en el procedimiento? Entra\n" +
              "              aquí y en minutos podrás elaborar un recurso de apelación por ti\n" +
              "              mismo.",
          tipo: 4,
          grupo: 6,
        },
       {
          id: 4,
          nombre: "Autoridad Municipal Auxiliar (RAA)",
          descripcion: "¿Eres autoridad municipal auxiliar de elección popular ?\n"+ 
          "¿Sabías que el juicio electoral ciudadano protege tu derecho político-electoral en la vertiente de desempeñar el cargo y recibir el pago correspondiente?\n" + 
          "Elabora tu propia demanda de manera eficaz y eficiente.",
          tipo: 5,  
          grupo: 4,
        },
        {
          id: 5,
          nombre: "Juicio de Revisión Constitucional Electoral (JRC)",
          descripcion: "¿Eres representante partidista y estás inconforme con la decisión del órgano jurisdiccional local?\n" + "Elabora tu propio escrito de revisión constitucional. O acércate a nuestro equipo de especialistas para apoyarte con la impugnación respectiva.",
          tipo: 6,  
          grupo: 5,
        },
        {
          id: 6,
          nombre: "Juicio Ciudadano (JDC-CPVF)",
          descripcion: "¿Requieres la reposición urgente de tu credencial para votar por robo o extravío? Aún estás a tiempo de proteger tu derecho al voto mediante un juicio ciudadano realizado de manera rápida y eficaz. Realiza tú mismo la petición en tiempo récord",
          tipo: 7,
          grupo: 7,
        },
        {
          id: 7,
          nombre: "Recurso de Inconformidad (RIN)",
          descripcion: "Una vez concluidos los cómputos distritales y municipales en caso de inconformidad tienes la oportunidad de presentar un medio de impugnación. Consulta como puedes hacerlo por ti mismo de manera rápida y eficaz",
          tipo: 8,
          grupo: 8,
        },
        {
          id: 8,
          nombre: "Juicio de Inconformidad (JIN)",
          descripcion: "Una vez concluidos los cómputos distritales a nivel federal en caso de inconformidad tienes la oportunidad de presentar un medio de impugnación. Consulta como puedes hacerlo por ti mismo de manera rápida y eficaz",
          tipo: 9,
          grupo: 8,
        },
        {
          id: 11,
          nombre: "Recurso de Revisión del PES (REP)",
          descripcion: "¿Necesitas promover un medio de impugnación contra la declaración de incompetencia de un órgano local del INE? Redacta por ti mismo el recurso correspondiente de manera práctica, sencilla y eficaz.",
          tipo: 11,
          grupo: 11,
        },
        /*
        {
          id: 9,
          nombre: "Escrito de Tercer Interesado",
          descripcion: "Una vez concluidos los cómputos distritales a nivel federal en caso de inconformidad tienes la oportunidad de presentar un medio de impugnación. Consulta como puedes hacerlo por ti mismo de manera rápida y eficaz",
          tipo: 10,
          grupo: 10,
        },*/
        {
          id: 12,
          nombre: "Acción Afirmativa Migrante (JDC)",
          descripcion: "¿Perteneces a un grupo vulnerado y/o pretendes solicitar la aplicación de una acción afirmativa? Elabora por ti mismo(a) la demanda para garantizar el derecho del grupo al que perteneces.",
          tipo: 12,
          grupo: 12,
        },
        {
          id: 13,
          nombre: "Recurso de Apelación (RAP)",
          descripcion: "¿Eres representante partidista ante el órgano administrativo electoral? Apóyate en nuestro formato digital para formular tu recurso de apelación contra la determinación sobre la omisión de reporte de gasto de pautas sin una valuación con bases objetivas.",
          tipo: 13,
          grupo: 6,
        },
        {
          id: 14,
          nombre: "Recurso de Reconsideración (REC)",
          descripcion: "¿Quieres quejarte por la indebida asignación de diputados por el principio de representación proporcional a nivel federal? Utiliza en tu beneficio nuestro formato digital y/o solicita la adecuación a tus circunstancias específicas.",
          tipo: 14,
          grupo: 14,
        },
        {
          id: 15,
          nombre: "Apelación contra emplazamiento (RAP)",
          descripcion: "¿Te emplazaron a un procedimiento de fiscalización en materia electoral sin las garantías del debido proceso? Elabora tu propio recurso en línea y defiende tus derechos de manera pronta, completa y eficaz.",
          tipo: 15,
          grupo: 15,
        },
        {
          id: 16,
          nombre: "Juicio ciudadano (JDC) contra ajuste por paridad",
          descripcion: "¿Estás disconforme con el ajuste determinado por la autoridad electoral para cumplir con la paridad en la cámara de diputados federal? Formula en línea tu propia demanda de juicio ciudadano de manera amigable, pronta y eficaz.",
          tipo: 16,
          grupo: 16,
        },
        {
          id:99,
          nombre:"Crea tu formato",
          descripcion: "Para continuar, selecciona a la derecha el formato que deseas crear",
          tipo:99,
          grupo:99,
        }
        
      
      ]
      
    }
  },
  methods: {
    formatosPorGrupo(grupo) {
      if (grupo == 1) {
        /*return this.formatos;*/
        return this.formatos.filter(f => f.grupo === 1)
        /*this.formatos.filter(f => f.grupo === 1)*/
      }
      return this.formatos.filter(f => f.grupo === grupo);
    },
    verForm(tipo) {
      let name = "";
      switch (tipo) {
        case 1:    
          name = "violencia";
          break;
        case 2:
          name = "denuciaViolencia";
          break;
        case 3:
          name = "solicitudInfo";
          break;
        case 4:
          name = "apelacion";
          break;
        case 5:
          name = "agentesMunicipales"
          break;
        case 6:
          name = "juicioDeRevision"
          break;
        case 7:
          name = "jdcCredencial"
          break;
        case 8:
          name = "recursoRIN"
          break;
        case 9:
          name = "juicioJIN"
          break;
        case 10:
          name = "tercerInteresado"
          break;
        case 11:
          name = "recursoREP"
          break;
        case 12:
          name = "jdcAF"
          break;
        case 13:
          name = "recursoRAP"
          break;
        case 14:
          name = "recursoREC"
          break;
        case 15:
          name = "recursoRAP2"
          break;
        case 16:
          name = "recursoJDC2"
          break;
      }
      this.$router.push({name});
    },
    async saveCorreos(){
            const today = Date.now();
            const data = {
                correo: this.correoSus,
                fechaRegistro: today,
            }
            await fr
            .collection("correosBD")
            .doc()
            .set({data})
        }
  },  
  
};

</script>
<style scoped>

.newsletter-twitter {
  display: flex;
  flex-direction: column;  
  align-content: normal;
  align-items: center;
}
#correoSuscribir{
  width: 24em;
  background-color: #e3d6ff;
}
.btn{
  width: 24em;
}
.row-lista-items{
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: clamp(16px,3vw,18px);
  align-content: center;
  align-items: center;  
}
.promo-wrapper{
  cursor: pointer;
  max-width: 300px;
  /*font-size: clamp(16px,3vw,18px);*/
  font-family: 'Poppins', sans-serif;
}


.promo-wrapper>h3{
  font-size: clamp(20px,3.2vw,28px);

}
.promo-wrapper>p{
  font-size: (18px,2.5vw,20px);
  font-family: 'Poppins', sans-serif;
}

.homepage-3 
{
  font-size: 10px;
}

.single-promo 
{
  font-size: 10px;
}

h3 {
  font-size: 25px;
}


.active{
  background: none;
}
/*      <div class="encabezados" id="izquierda">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-vgp-tab" data-toggle="pill" href="#pills-vgp" role="tab"
               aria-controls="pills-vgp" aria-selected="true">**/ 

.nav-pills .nav-link-cost.active, .nav-pills .show>.nav-link-cost {
  background-color: #592f76;
  border: solid 7px #a5a3a9;
  color: rgb(255, 255, 255);
  font-weight: 700;
}

.nav-pills .nav-link-free.active, .nav-pills .show>.nav-link-free {
  background-color: #e3d6ff;
  border: solid 7px #2f0c71;
  color: #000;
  font-weight: 700;
}

.nav-pills .nav-link-free, .nav-pills >.nav-link-free {
  background-color: #e3d6ff;
  /*border-bottom: solid 5px #e3d6ff;*/
  color: #000;
  width: 300px;
}

.nav-pills .nav-link-cost, .nav-pills >.nav-link-cost {
  background-color: #592f76;
  /*border: solid 5px #2f0c71;*/
  color: rgb(255, 255, 255);
  width: 300px;
}

.nav-pills .nav-link-free, .nav-link-cost {
  border-radius: 0.25rem;
}

.nav-link-free, .nav-link-cost{
  display: block;
  padding: 0.5rem 1rem;
}

.encabezados{
/*  background-color: ;*/
  overflow: hidden;
  margin-right: 5px;
  display: inline-flex;
  width: 300px;
  align-content: left;
}

.homepage-3 .grad-hover::before {
    background: linear-gradient(135deg, #10054d 0%, #45108a 100%);
}

.row-items{
  margin-bottom: 25px;
 flex-direction: row;
 flex-wrap: wrap;
 align-content: left;
 justify-content: left;
}
.col-12{
  display: inline;
  width: 35px;
}
.lista-twitter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  

}
.embebed-twitter{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50%;
}
</style>