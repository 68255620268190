import { auth, fr } from '../../firebase';


export default {
  async guardarFormato({ getters }, { nombreFormato, tipoFormato }) {
    const dataGeneral = {
      fechaRegistro: Date.now(),
      estatus: 1,
      tipoFormato,
      nombreFormato,
      usuarioInfo: {
        nombre:
          getters.getFormatoData.nombres +
          " " +
          getters.getFormatoData.appPat +
          " " +
          getters.getFormatoData.appMat,
        correo: getters.getFormatoData.correo,
        telefono: getters.getFormatoData.telefono,
        estado: getters.getFormatoData.estado,
      },
    };

    if ( auth.currentUser ) {
      dataGeneral.usuarioRegistro = auth.currentUser.uid;
    }

    // Aqui se asignan los costos a los formatos correspondientes.
    switch ( tipoFormato) {
      case 4:
        dataGeneral.costo = 100;
        break;
      case 5:
        dataGeneral.costo = 150;
        break;
      case 6:
        dataGeneral.costo = 150;
        break;
      case 7:
        dataGeneral.costo = 150;
        break;
      case 8:
        dataGeneral.costo = 150;
        break;
      case 10:
        dataGeneral.costo = 150;
        break;
    }

    const docReference = fr.collection("formatosData").doc();
    const formatosGeneral = fr
      .collection("formatosGeneral")
      .doc(docReference.id);

    await formatosGeneral.set(dataGeneral);
    await docReference.set(getters.getFormatoData);
  },
  async generarPDF({getters}, {url, nombreFormato, data }) {
    console.log(getters.getFormatoData);
    console.log(url);
    const body = data != undefined ? JSON.stringify(data ) : JSON.stringify(getters.getFormatoData);
    const res = await fetch(url, {
        method: "POST",
        body:body,
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.blob());
    console.log(res);
    const newBlob = new Blob([res], { type: "application/pdf" });
    const objUrl = window.URL.createObjectURL(newBlob);
    let link = document.createElement("a");
    link.href = objUrl;
    link.download = nombreFormato;
    link.click();
  },

  async guardarPrediccion({ getters }, { nombreFormato, tipoFormato, datosExpediente }) {
    const dataGeneral = {
      fechaRegistro: Date.now(),
      estatus: 1,
      tipoFormato,
      //ponente,
      //tipoImpugnacion,
      //modalidadesViolencia,
      datosExpediente: datosExpediente,
      nombreFormato,
      usuarioInfo: {
        nombre:
          getters.getFormatoData.nombres +
          " " +
          getters.getFormatoData.appPat +
          " " +
          getters.getFormatoData.appMat,
        correo: getters.getFormatoData.correo,
        telefono: getters.getFormatoData.telefono,
        
      },
    };

    if ( auth.currentUser ) {
      dataGeneral.usuarioRegistro = auth.currentUser.uid;
    }
    console.log("Contenido de getters:", getters); 
    console.log(`${tipoFormato}`)
    // Accede al documento X dentro de la colección "predicciones"
    const doc = fr.collection("predicciones").doc(`${tipoFormato}`);

    // Crea una nueva subcolección llamada "PP" dentro del documento 
    const prediccionPreviaRef = doc.collection("PPRV");

    // Agrega un nuevo documento a la subcolección "PPRV"
    await prediccionPreviaRef.add(dataGeneral);

    //const ml = firebase.ml();
    //  const model = ml.model('VPG_SENTIDO');
    //  const prediction = await model.predict(this.datosExpediente);
    //  this.predictionResult = prediction;
    //  
    //this.formData.predictionResult = prediction;
    //  await firestoreRef.add(this.formData);

    // Puedes obtener el ID del nuevo documento creado si lo necesitas
    //const newDocumentId = newDocumentRef.id;
    
    /*
    const docReference = fr.collection("predicciones").collection("VPG").doc();
    const prediccion = fr
      .collection("predicciones")
      .collection("VPG")
      .doc(docReference.id);

    await prediccion.set(dataGeneral);
    await docReference.set(getters.getFormatoData);
    */
  },


  async oneHotEncodingVPG(datos) {
    const columnasOneHot = [
        'TipoInpugnacion_AG', 'TipoInpugnacion_JDC', 'TipoInpugnacion_JE', 'TipoInpugnacion_JRC',
        'TipoInpugnacion_PES', 'TipoInpugnacion_PSC', 'TipoInpugnacion_RAP', 'TipoInpugnacion_REC',
        'TipoInpugnacion_REP', 'TipoInpugnacion_RIN',
        'Ponente_Adín Antonio de León Gálvez', 'Ponente_Claudia Díaz Tablada', 'Ponente_Enrique Figueroa Ávila',
        'Ponente_Ernesto Camacho Ochoa', 'Ponente_Eva Barrientos Zepeda', 'Ponente_Felipe Alfredo Fuentes Barrera',
        'Ponente_Felipe de la Mata Pizaña', 'Ponente_Gabriela Eugenia Del Valle Pérez',
        'Ponente_Gabriela Villafuerte Coello', 'Ponente_Gustavo César Pale Beristain',
        'Ponente_Indalfer Infante Gonzales', 'Ponente_Indalfer Infante González', 'Ponente_Janine M.Otálora Malassis',
        'Ponente_José Antonio Hernández Huesca', 'Ponente_José Antonio Troncoso Ávila',
        'Ponente_José AntonioHernández Huesca', 'Ponente_José Luis Vargas Valdez', 'Ponente_José Oliveros Ruiz',
        'Ponente_Luis Espíndola Morales', 'Ponente_Marcela Elena Fernández Domínguez',
        'Ponente_Mónica Aralí Soto Fregoso', 'Ponente_Reyes Rodríguez Mondragón',
        'Ponente_Roberto Eduardo Sigala Aguilar', 'Ponente_Roberto Eduardo Sígala Aguilar',
        'Ponente_Rubén Jesús Lara Patrón', 'Ponente_Sergio Arturo Guerrero Olvera',
        'Ponente_Tania Celina Vásquez Muñoz', 'ModalidadesViolenciaPolitica_Frases en RRSS que contribuyen a vpg',
        'ModalidadesViolenciaPolitica_Violencia simbólica', 'ModalidadesViolenciaPolitica_acoso laboral',
        'ModalidadesViolenciaPolitica_discriminación y vpg', 'ModalidadesViolenciaPolitica_no remuneración del cargo',
        'ModalidadesViolenciaPolitica_obstruccion del cargo ', 'ModalidadesViolenciaPolitica_obstrucción de cargo',
        'ModalidadesViolenciaPolitica_obstrucción del cargo', 'ModalidadesViolenciaPolitica_obstrucción del cargo y vpg',
        'ModalidadesViolenciaPolitica_obstrucción del ejercicio constituyendo vpg',
        'ModalidadesViolenciaPolitica_publicaciones en RRSS resultando vpg',
        'ModalidadesViolenciaPolitica_violencia Institucional y vpg', 'ModalidadesViolenciaPolitica_violencia política',
        'ModalidadesViolenciaPolitica_violencia psicológica', 'ModalidadesViolenciaPolitica_vpg',
        'ModalidadesViolenciaPolitica_vpg nulidad de elección', 'ModalidadesViolenciaPolitica_vpg, obstrucción del cargo',
        'ModalidadesViolenciaPolitica_vpg, simbólica'
    ];

    // let nuevaInstancia = {};

    // columnasOneHot.forEach(columna => {
    //     nuevaInstancia[columna] = 0;
    // });

    // for (let [columna, valores] of Object.entries(datos)) {
    //     if (!Array.isArray(valores)) {
    //         valores = [valores]; // Asegurar que valores sea un array
    //         console.log("Esto es valores: ",valores)
    //     }
    //     valores.forEach(valor => {
    //         let nombreColumna = `${columna}_${valor}`;
    //         console.log("Esto es columna: ",`${columna}_${valor}`)
    //         if (nuevaInstancia.hasOwnProperty.call(nombreColumna)) {
    //             nuevaInstancia[nombreColumna] = 1;
    //             console.log("Esto es un 1: ",nuevaInstancia[nombreColumna])
    //         }
    //     });
    // }

    // let datosEnFormatoOneHot = {};

    // // Itera sobre cada elemento en datos y asigna valores one-hot
    // for (const columna in datos) {
    //   const valor = datos[columna][0]; // Obtén el primer valor (asumiendo que es un solo valor)
    //   const nombreColumna = `${columna}_${valor}`;
      
    //   // Verifica si la columna existe en columnasOneHot
    //   if (columnasOneHot.includes(nombreColumna)) {
    //     datosEnFormatoOneHot[nombreColumna] = 1; // Asigna 1 si la columna está presente
    //   }
    // }

    // // Crea un array one-hot basado en columnasOneHot y datosEnFormatoOneHot
    // const oneHotEncodedArray = columnasOneHot.map(columna => {
    //   const valor = datosEnFormatoOneHot[columna] ? 1 : 0;
    //   return valor;
    // });

    //console.log("SOY DATOS EN ACTIONS", datos)
     // Verificar y asegurar que los datos no sean undefined
    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const modalidadViolencia = datos.getters.getFormatoData.modalidadesViolencia;

    // Verificar los valores antes de crear las nuevas columnas
    // console.log("tipoImpugnacion:", tipoImpugnacion);
    // console.log("ponente:", ponente);
    // console.log("modalidadViolencia:", modalidadViolencia);

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `ModalidadesViolenciaPolitica_${modalidadViolencia}`
    ];

    // console.log("NUEVAS COLUMNAS ", nuevasColumnas)

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },


  async oneHotEncodingPP(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_PES",
      "TipoInpugnacion_PSC",
      "TipoInpugnacion_PSL",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "TipoInpugnacion_REP",
      "Ponente_Claudia Díaz Tablada",
      "Ponente_Claudia Valle Aguilasocho",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Gabriela Villafuerte Coello",
      "Ponente_Indalfer Infante Gonzales",
      "Ponente_Indalfer Infante Gonzáles ",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_Janine M.Otálora Malassis",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Luís Espíndola Morales",
      "Ponente_María Guadalupe Silva Rojas",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "Ponente_Rubén Jesús Lara Patrón",
      "Ponente_Sergio Iván Redondo Toca",
      "PropagandaProhibida_Actos anticipados de campaña",
      "PropagandaProhibida_Coacción, Uso indebido de recursos",
      "PropagandaProhibida_Discriminación",
      "PropagandaProhibida_Discriminación y Uso indebido de recursos públicos",
      "PropagandaProhibida_Fraude electoral",
      "PropagandaProhibida_Irregularidades en la elección",
      "PropagandaProhibida_Promoción personalizada",
      "PropagandaProhibida_Promoción personalizada, uso indebido de recursos públicos,",
      "PropagandaProhibida_Uso indebido de recursos",
      "PropagandaProhibida_Uso indebido de recursos públicos",
      "PropagandaProhibida_Uso indebido de recursos públicos y Actos anticipados de campaña",
      "PropagandaProhibida_Violación a las reglas electorales",
      "PropagandaProhibida_Violación de normativas electorales",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const PropagandaProhibida = datos.getters.getFormatoData.propagandaProhibida;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `ModalidadesViolenciaPolitica_${PropagandaProhibida}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingDI(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_CDC",
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JIN",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "TipoInpugnacion_REP",
      "Ponente_Alfredo Fuentes Barrera",
      "Ponente_Enrique Figueroa Ávila",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Indafer Infante Gonzáles",
      "Ponente_Indalfer Infante Gonzales",
      "Ponente_Indalfer Infante Gonzáles",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_Janine M.Otálora Malassis",
      "Ponente_José Antonio Troncoso Ávila",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      'Modalidades_"Convocatoria al III Congreso Nacional Ordinario" del citado partido político.',
      "Modalidades_Afiliación sin consentimiento",
      "Modalidades_Aprobación de convocatoria para participar en las candidaturas independientes a las diputaciones de Nuevo Leon",
      "Modalidades_Aprobación de la solicitud de registro de presentada por una organización para constituirse como partido político local.",
      "Modalidades_Aprobación de lineamientos para garantizar el cumplimiento del principio de paridad de género y no discriminación en la postulación de candidaturas a diputaciones por los principios de mayoría.",
      "Modalidades_Asignación de Diputados ",
      "Modalidades_Asignación de diputaciones por dicho principio para integrar el Congreso de esa entidad.",
      "Modalidades_Asignación de diputados por el principio de representación proporcional para integrar el congreso",
      "Modalidades_Asignación de diputados por el principio de representación proporcional.",
      "Modalidades_Asignación de regidores por el principio de representación proporcional. ",
      "Modalidades_Asignación de regidurías",
      "Modalidades_Cargo directivo partidista",
      "Modalidades_Cargo municipal",
      "Modalidades_Convenio de coalición",
      "Modalidades_Convenio de coalición ALIANZA CIUDADANA POR COAHUILA",
      "Modalidades_Convocatorias y Elecciones",
      "Modalidades_Criterios, metodología y fórmula para la distribución de los recursos de las participaciones fiscales federales, entre las agencias de polícia que pertenecen a aquél, así como la transparencia directa de los recursos.",
      "Modalidades_Cumplimiento de diversos requisitos para la obtención del registro respectivo.",
      "Modalidades_Cumplimiento del procentaje de apoyo ciudadano requerido para su registro al aludido cargo de elección popular.",
      "Modalidades_Declaración de validez de la elección y asignación de diputaiones por el princio de representación proporcional de ese estado",
      "Modalidades_Declaración de validez de la elección y asignación de diputaiones por el princio de representación proporcional que integrarán la XXII Legislatura del Congreso de la aludida entidad.",
      "Modalidades_Decreto por el que se reformaron, adicionaron y derogaron disposiciones",
      "Modalidades_Derecho a la administración directa de los recursos",
      "Modalidades_Duración del cargo",
      "Modalidades_Elección del Comité Directivo del Partido Nacional en Veracruz y cadena impugnativa",
      "Modalidades_En contra de la convocatoria",
      "Modalidades_Equivalencias al procentaje de apoyo ciudadano requerido para el registro de candidatos independientes Goberdor.",
      "Modalidades_Expedición de convocatoria para postular al candidato independiente.",
      "Modalidades_Forma en que se deben realizar los ajustes en la asignaión de curules por representación proporcional derivado de la revisión de los límites de sobre y sub representación.",
      "Modalidades_Improcedente el derecho a registrar como candidato independiente a presidente municipal",
      'Modalidades_Invalidez parcial de la cláusula quinta del convenio de la coalición parcial "Juntos Haremos Historia".',
      "Modalidades_Irregularidades detectadas por la Dirección Ejecutiva del RFE en el proceso de obtención de apoyo ciudadano para el registro d ecandidaturas independientes a cargas federales de elección popular para el proceso electoral federal 2017-2018.",
      "Modalidades_Manifestación de intención presentada por el ahora actor de participar en el proceso electoral local ordinario 2018-2019 al cargo de gobernador del estado de Baja California.",
      "Modalidades_Modificación de la diversa del Tribunal Electoral Local relacionada con la elección extraordinaria del ayunatmiento de Monterrey.",
      "Modalidades_Modificó la asignación del aludido cargo de elección popular y ordenó la entrega de constancias a Roberto de Jesús Núñez Vizzuett.",
      "Modalidades_Modificó la asignación del aludido cargo de elección popular.",
      "Modalidades_No otorgar el registro al cargo de gobernador",
      "Modalidades_No otorgar el registro como candidato independiente al cargo de gobernador",
      "Modalidades_No se reunió el porcentaje de apoyo ciudadano como aspirante a candidato independiente a diputado federal por el principio de mayoría relativa, en el 8 distrito en Michoacán.",
      "Modalidades_Omisión del Consejo General del Instituto Nacional Electoral, de establecer el marco normativo aplicable que garantice el cumplimiento de los principios rectores de los procesos electorales en el contexto de la renovación mediante encuesta abierta de la Presidencia",
      "Modalidades_Posible contradicción de criterioss ",
      "Modalidades_Postulación de candidatas y candidatos comunes oa diputadas y diputados locales por el principio de mayoría relativa.",
      "Modalidades_Procedimiento para la obtención del procentaje de apoyo ciudadano para el registro de candidaturas independientes.",
      "Modalidades_Prohibición de una constitución se constituiya como partido",
      "Modalidades_Propuesta para integrar la mesa diectiva del senado",
      "Modalidades_Protesta Magistratura local",
      "Modalidades_Quinta circunscripción plurinominal, con sede en Toluca, EDOMex, plantea la posible contradicción de criterios entre lo sustentado por la referida Sala regional al resolver los juicios sustentados por la sala regional, correspondiente a ka segunda circunscripción plurinominal con sede en Monterrey,Nuevo León.",
      "Modalidades_Registro de convenio de coalición",
      "Modalidades_Registro de convenio de coalición para postular candidatura a Gobernador.",
      "Modalidades_Registro del ciudadano actor como candidato al aludido cargo.",
      "Modalidades_Registro del convenio de coalición para postular Gobernador, así como de candidatura común para diputados locales por el principio de mayoría relativsa y ayuntamientos.",
      "Modalidades_Requisitos para el registro de candidatos independientes.",
      "Modalidades_Restitución del cargo",
      "Modalidades_Resultados de contienda",
      "Modalidades_Revocación de la diversa en relación con la asignación de regidores por el principio de representación proporcional del ayuntamiento.",
      "Modalidades_Revocación de registro como candidato a cargo de presidente municipal.",
      "Modalidades_Se aprobó el instructivo que deberán observar los partidos políticos nacionales que busquen formar coaliciones.",
      "Modalidades_Solicitud de registro al aludido cargo.",
      "Modalidades_Solicitud para ser designado como Senadores propietarios bajo los principios de representación proporcional y mayoría relativa.",
      "Modalidades_Supuesta violación de los principios de estado laico y separación iglesia-estado.",
      "Modalidades_Suspensión de actividades",
      "Modalidades_Validez de elección de diputados por el principio de representación proporcional y asignó a diversos partidos políticos las diputaciones que le corresponden para el periodo 2018-2021.",
      "Modalidades_a renovación del Consejo Estatal y la elección de integrantes del Nacional del referido instituto político, para el periodo 2019-2022",
      "Modalidades_afiliación al padrón",
      "Modalidades_ampliación del plazo legal para la celebración de asambleas y del término del proceso.",
      "Modalidades_cancelación del tercer congreso estatal ordinario del partido socialista",
      "Modalidades_constitución de un nuevo partido local",
      "Modalidades_contadicción de criterios, con la foma de computar los plazos en los procesos internos de renovación de dirigencias del PAN",
      "Modalidades_contratación de dos personas pertenecientes a su familia adscritas a la cámara de Diputados del Congreso",
      "Modalidades_convocatoria para la elección de la Presidencia",
      "Modalidades_convocatoria para que se autoadscriban como simpatizantes ",
      "Modalidades_correo enviado desde una cuenta institucional",
      "Modalidades_derechos de la militancia",
      "Modalidades_destitución del cargo",
      "Modalidades_duración de los cargos de integrantes ratificados y nombrados del comité ejecutivo nacional del morena",
      "Modalidades_elegibilidad del cargo",
      "Modalidades_emisión de convocatoria a la sesion del VI congreso nacional extraordinario de MORENA.",
      "Modalidades_emitió los Lineamientos sobre elección consecutiva de diputaciones por ambos principios para el proceso electoral federal 2020-2021.",
      "Modalidades_exclusión  del padrón de militantes",
      "Modalidades_expulsion de un militante de un partido político",
      "Modalidades_financiamiendo público",
      "Modalidades_impugnación de la elección del candidato",
      "Modalidades_inconformidad con resultados",
      "Modalidades_inconformidad intrapartidario en contra de resultados",
      "Modalidades_indebida afiliación",
      "Modalidades_indebida afiliación, uso de datos personales de un ciudadano",
      "Modalidades_inscripción involuntaria",
      "Modalidades_instructivo a observar las organizaciones interesadas en constituir un partido político nacional",
      "Modalidades_irregularidades en el registro de afiliaciones a través de la app",
      "Modalidades_juicio intrapartidista",
      "Modalidades_legalidad del nombramiento del gobernador sustituro en la referida entidad efectuado por el congreso local",
      "Modalidades_lineamientos sobre la instrumentacion de la base quinta.",
      "Modalidades_modificación de estatuto",
      "Modalidades_no hay perdida de acreditación a pesar de o haber obtenido al menos 3%",
      "Modalidades_nombramiento como delegada",
      "Modalidades_nombramiento de los miembros de la comisión nacional de honestidad y justicia.",
      "Modalidades_omisión a solicitudes sobre transferencia",
      "Modalidades_omisión de colocar el logotipo de una coalición, pinta de bardas.",
      "Modalidades_paridad en la elección",
      "Modalidades_participación de un sindicatos en actividades de conformación de un partido político, RSP.",
      "Modalidades_por la falta de actualización y supuestas inconsistencias en el citado padrón",
      "Modalidades_posibilidad de elección consecutiva en los cargos de Ayuntamientos",
      "Modalidades_procesos internos",
      "Modalidades_publicación de un padrón actualizado",
      "Modalidades_pérdida de registro",
      "Modalidades_reconocimiento de la representación de dicha asociación",
      'Modalidades_registro como partido político a la organización "Partido Encuentro Solidario"',
      'Modalidades_registro como partido político a la organización "RSP"',
      "Modalidades_registro de delegada",
      "Modalidades_renovación de lor órganos internos del partido político",
      "Modalidades_renovación de los cargos de ese instinto de ese instituto político",
      "Modalidades_renovación del comité del PAN",
      "Modalidades_revisión de requisitos para constituirse como partido político",
      "Modalidades_se emite la Convocatoria y se aprueban los Lineamientos para la Verificación del cumplimiento del porcentaje de apoyo de la ciudadanía inscrita en la lista nominal de electores que se requiere para el registro de candidaturas independientes para diputaciones federales",
      "Modalidades_separación provisional del cargo",
      "Modalidades_suspención por tiempo determinado",
      "Modalidades_sustitucion del cargo",
      "Modalidades_uso indebido de recursos públicos",
      "Modalidades_uso indebido de recursos públicos y promoción personalizada, derivado de la inclusión de su nombre en las cartas que se hacían llegar a los beneficiarios del Programa de Apoyo Financiero a Microempresas Familiares,",
      "Modalidades_usurpación de funciones, manipulación de voluntades al realizar actos de proselitismo y campaña a su favor",
      "Modalidades_violación procedimental porla indebida admisión de las confesiones ofrecidas por la parte denunciante",
      "Modalidades_órganos directivos",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const Modalidades = datos.getters.getFormatoData.modalidades;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `Modalidades_${Modalidades}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingFn(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_CDC",
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_PSC",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "TipoInpugnacion_rap",
      "TipoInpugnacion_rep",
      "Ponente_Claudia Valle Aguilasocho",
      "Ponente_Elena Ponce Aguilar",
      "Ponente_Enrique Figueroa Ávila",
      "Ponente_Ernesto Camacho Ochoa",
      "Ponente_Eva Barrientos Zepeda",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Gabriela Eugenia Del Valle Pérez",
      "Ponente_Indalfer Infante Gonzales",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_Janine M.Otálora Malassis",
      "Ponente_José Antonio Troncoso Ávila",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Marcela Elena Fernández Domínguez",
      "Ponente_María Guadalupe Silva Rojas",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Omar Delgado Chávez",
      "Ponente_Reyes Rodríguez Mondragón",
      "Ponente_Rubén Jesús Lara Patrón",
      "Ponente_Sergio Arturo Guerrero Olvera",
      "TiposInfraccion_Cambios y Nombramientos",
      "TiposInfraccion_Eventos y Actos de Campaña",
      "TiposInfraccion_Financiamiento y Presupuesto",
      "TiposInfraccion_Irregularidades en Finanzas y Gastos",
      "TiposInfraccion_Irregularidades en Informes y Procedimientos",
      "TiposInfraccion_Otros",
      "TiposInfraccion_Procedimientos Administrativos",
      "TiposInfraccion_Regulaciones y Lineamientos",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const TiposInfraccion = datos.getters.getFormatoData.tiposInfraccion;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `TiposInfraccion_${TiposInfraccion}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingAAC(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_PES",
      "TipoInpugnacion_PSC",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REP",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Gabriela Villafuerte Coello",
      "Ponente_Indalfer Infante Gonzales",
      "Ponente_Janine M. Ótalora Malassis",
      "Ponente_José Antonio Hernández Huesca",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Luis Espíndola Morales",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "Ponente_Roberto Eduardo Sigala Aguilar",
      "Ponente_Rubén Jesús Lara Patrón",
      "ModalidadesActos_Difusión en Radio/TV",
      "ModalidadesActos_Difusión en Redes Sociales",
      "ModalidadesActos_Difusión en Redes Sociales y Plataformas Online",
      "ModalidadesActos_Difusión en Redes Sociales, Equipamiento Urbano",
      "ModalidadesActos_Difusión en Redes Sociales, Publicidad Audiovisual",
      "ModalidadesActos_Difusión en Redes Sociales, Uso Indebido de Recursos Públicos",
      "ModalidadesActos_Entrevistas en Medios",
      "ModalidadesActos_Entrevistas en Medios, Difusión en Redes Sociales",
      "ModalidadesActos_Eventos de Campaña",
      "ModalidadesActos_Eventos de Campaña, Difusión en Redes Sociales",
      "ModalidadesActos_Eventos de Campaña, Equipamiento Urbano",
      "ModalidadesActos_Eventos de Campaña, Publicidad Exterior",
      "ModalidadesActos_Eventos de Campaña, Uso Indebido de Recursos Públicos",
      "ModalidadesActos_Notas Periodísticas, Difusión en Redes Sociales",
      "ModalidadesActos_Publicidad Exterior",
      "ModalidadesActos_Publicidad Exterior, Difusión en Redes Sociales",
      "ModalidadesActos_Publicidad Exterior, Entrevistas en Medios",
      "ModalidadesActos_Publicidad Exterior, Equipamiento Urbano",
      "ModalidadesActos_Publicidad Exterior, Publicidad Personalizada",
      "ModalidadesActos_Publicidad Personalizada",
      "ModalidadesActos_Publicidad Personalizada, Uso Indebido de Recursos Públicos",
      "ModalidadesActos_Publicidad Personalizada, Uso Indebido de Recursos Públicos, Violación a Principio de Equidad",
      "ModalidadesActos_Publicidad Personalizada, Violación a Principio de Imparcialidad, Uso Indebido de Recursos Públicos, Entrevistas en Medios",
      "ModalidadesActos_Publicidad en Radio/TV",
      "ModalidadesActos_Uso Indebido de Recursos Públicos",
      "ModalidadesActos_Uso Indebido de Recursos Públicos, Difusión en Redes Sociales",
      "ModalidadesActos_Uso Indebido de Recursos Públicos, Publicidad Exterior",
      "ModalidadesActos_Uso Indebido de Recursos Públicos, Publicidad Personalizada",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const ModalidadesActos = datos.getters.getFormatoData.modalidadesActos;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `ModalidadesActos_${ModalidadesActos}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingAA(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_CDC",
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "Ponente_Enrique Figueroa Ávila",
      "Ponente_Eva Barrientos Zepeda",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Indalfer Infante Gonzáles",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_Jesús Eduardo Bautista Peña",
      "Ponente_Jorge Sánchez Morales",
      "Ponente_José Antonio Hernández Huesca",
      "Ponente_José Antonio Troncoso Ávila",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Manuel Alberto Cruz Martínez",
      "Ponente_Manuel González Oropeza",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "Ponente_Tania Celina Vásquez Muñoz",
      "Ponente_Tomás Vargas Suárez",
      "TipoAccionAfirmativa_Comunidad LGBT+",
      "TipoAccionAfirmativa_Comunidades Indígenas",
      "TipoAccionAfirmativa_Otros",
      "TipoAccionAfirmativa_Paridad de Género",
      "TipoAccionAfirmativa_Paridad de Género, Comunidad LGBT+",
      "TipoAccionAfirmativa_Paridad de Género, Comunidades Indígenas, Otros",
      "TipoAccionAfirmativa_Paridad de Género, Sistemas normativos internos",
      "TipoAccionAfirmativa_Personas con discapacidad",
      "TipoAccionAfirmativa_Representación migrante",
      "TipoAccionAfirmativa_Sistemas normativos internos",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const TipoAccionAfirmativa = datos.getters.getFormatoData.tipoAccionAfirmativa;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `TipoAccionAfirmativa_${TipoAccionAfirmativa}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingVE(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_CDC",
      "TipoInpugnacion_EA",
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JDCL",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JI",
      "TipoInpugnacion_JIN",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_REC",
      "TipoInpugnacion_RIN",
      "Ponente_ Enrique Figueroa Ávila",
      "Ponente_ Enrique Figueroa Ávila ",
      "Ponente_Adín Antonio de León Gálvez",
      "Ponente_Alejandro David Avante Juárez",
      "Ponente_Ana Carmen González Pimentel",
      "Ponente_Ana Violeta Iglesias Escudero",
      "Ponente_Celia Sofía de Jesús Ruíz Olvera",
      "Ponente_Claudia Salvador Ángel",
      "Ponente_Elizabeth Bautista Velasco",
      "Ponente_Enrique Figueroa Ávila",
      "Ponente_Ernesto Camacho Ochoa",
      "Ponente_Eva Barrientos Zepeda",
      "Ponente_Felipe Alfredo Fuente Barrera",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Indalfer Infante Gonzáles",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_Jesús Eduardo Bautista Peña",
      "Ponente_Jorge E. Muciño Escalona",
      "Ponente_Jorge Sánchez Morales",
      "Ponente_José Antonio Troncoso Ávila",
      "Ponente_José Luis Ceballos Daza",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_José Lumbreras García",
      "Ponente_Juan Carlos Silva Adaya",
      "Ponente_Leticia Victoria Tavira",
      "Ponente_Marcela Elena Fernández Domínguez",
      "Ponente_Martha Marín García",
      "Ponente_María Guadalupe Silva Rojas",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "Ponente_Ricardo Adrián Rodriguez Perdomo",
      "Ponente_Tania Cecilia Vásquez Muñoz",
      "Ponente_Tomás Vargas Suárez",
      "Ponente_Víctor Oscar Pasquel Fuentes",
      "TipoNulidad_Determinancia Constitucional",
      "TipoNulidad_Sistema Normativo Interno",
      "TipoNulidad_VPG",
      "TipoNulidad_VPG, Violación a Principios Constitucionales",
      "TipoNulidad_Violación a Normativa Electoral",
      "TipoNulidad_Violación a Principios Constitucionales",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const TipoNulidad = datos.getters.getFormatoData.tipoNulidad;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `TipoNulidad_${TipoNulidad}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingDMIOE(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_AG",
      "TipoInpugnacion_CDC",
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Indalfer Indalfer González",
      "Ponente_Indalfer Infante Gonzáles",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "Modalidades_Aprobación de Ampliación Presupuestaria",
      "Modalidades_Aprobación de Convocatorias",
      "Modalidades_Aprobación de Designación",
      "Modalidades_Aprobación de Designación en Órganos",
      "Modalidades_Aprobación de Listado",
      "Modalidades_Aprobación de Reglamentos Electorales",
      "Modalidades_Asignación de Diputaciones",
      "Modalidades_Asignación de Fraccionamientos y Cartografía Electoral",
      "Modalidades_Autorización de Medidas Extraordinarias",
      "Modalidades_Confirmación de Acuerdos",
      "Modalidades_Convocatoria para Designación",
      "Modalidades_Convocatoria para Designación de Cargos",
      "Modalidades_Convocatoria para Ocupar Plazas Vacantes",
      "Modalidades_Cuestiones Profesionales",
      "Modalidades_Designación de Cargos",
      "Modalidades_Designación de Ciudadanos",
      "Modalidades_Designación en Grupos Parlamentarios",
      "Modalidades_Designación y Remoción",
      "Modalidades_Designación y Toma de Protesta",
      "Modalidades_Establecimiento de Salarios",
      "Modalidades_Establecimiento de Salarios en Cargos",
      "Modalidades_Financiamiento Público",
      "Modalidades_Integración de Órganos",
      "Modalidades_Listado de No Aprobados",
      "Modalidades_Omisión de Pago",
      "Modalidades_Planificación Electoral",
      "Modalidades_Procedimiento de Designación",
      "Modalidades_Proceso de Ingreso a Cargos y Puestos",
      "Modalidades_Publicidad de Convocatorias",
      "Modalidades_Reanudación de Actividades Suspendidas",
      "Modalidades_Resultados de Concursos",
      "Modalidades_Revisión de Criterios",
      "Modalidades_Revocación y Restitución en Cargos",
      "Modalidades_Solicitud de Incorporación",
      "Modalidades_Suspensión de Actividades",
      "Modalidades_Verificación de Criterios y Requisitos de Designación",
      "Modalidades_Violación a Principios Constitucionales en el Desempeño del Cargo",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const Modalidades = datos.getters.getFormatoData.modalidades;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `Modalidades_${Modalidades}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },


  async oneHotEncodingTRTV(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_JE",
      "TipoInpugnacion_PSC",
      "TipoInpugnacion_REP",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Gabriela Villafuerte Coello",
      "Ponente_Indalfer Infante Gonzales",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "Ponente_Rubén Jesús Lara Patrón",
      "Modalidades_Medios de Comunicación",
      "Modalidades_Participación en Actos Públicos",
      "Modalidades_Principios Legales",
      "Modalidades_Regulaciones Electorales",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const Modalidades = datos.getters.getFormatoData.modalidades;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `Modalidades_${Modalidades}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingAP(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_REC",
      "Ponente_Felipe Alfredo Fuentes Barrrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Indalfer Infante Gonzales",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "CriteriosContiendaCambioCriterio_Cambio de grupo parlamentario",
      "CriteriosContiendaCambioCriterio_Conformación de órganos directivos",
      "CriteriosContiendaCambioCriterio_Integración de la JUCOPO ",
      "CriteriosContiendaCambioCriterio_nombramientos titulares de órganos autónomos",
      "CriteriosContiendaCambioCriterio_Órganos autónomos",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const CriteriosContiendaCambioCriterio = datos.getters.getFormatoData.criteriosContiendaCambioCriterio;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `CriteriosContiendaCambioCriterio_${CriteriosContiendaCambioCriterio}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingPG(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_JDC",
      "TipoInpugnacion_JE",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "Ponente_Enrique Figueroa Ávila",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Gabriela del Valle Pérez",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_Marcela Elena Fernández Domínguez",
      "Ponente_Mónica Aralí Soto Fregoso",
      "Ponente_Reyes Rodríguez Mondragón",
      "ModalidadParidad_elección constitucional",
      "ModalidadParidad_sistema normativo interno",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const ModalidadParidad = datos.getters.getFormatoData.modalidadParidad;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `ModalidadParidad_${ModalidadParidad}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

  async oneHotEncodingCV(datos) {
    const columnasOneHot = [
      "TipoInpugnacion_CDC",
      "TipoInpugnacion_JRC",
      "TipoInpugnacion_RAP",
      "TipoInpugnacion_REC",
      "Ponente_Felipe Alfredo Fuentes Barrera",
      "Ponente_Felipe de la Mata Pizaña",
      "Ponente_Indalfer Infante Gonzáles",
      "Ponente_Janine M. Otálora Malassis",
      "Ponente_José Luis Vargas Valdez",
      "Ponente_Reyes Rodríguez Mondragón",
      "TipoEleccion_Conteo rápido para la elección de titulares del ejecutivo federal y locales .",
      "TipoEleccion_Expedición de constancia de mayoría",
      "TipoEleccion_Se aprueba  reanudar las actividades inherentes a su desarrollo, así como ajustes al plan integral y calendarios de coordinación, así como las consecuencias directas o indirectas, mediatas o inmediatas, que se generen con motivo.",
      "TipoEleccion_Se confirmó la declaración de validez de la elección de Gobernador.",
      "TipoEleccion_cambio de criterio",
      "TipoEleccion_constancia de mayoría",
      "TipoEleccion_declaración de nulidad de la elección",
      "TipoEleccion_declaración de revocación de validez",
      "TipoEleccion_declaración de validez de la elección",
      "TipoEleccion_nulidad de elección de integrantes de ayuntamiento,",
      "TipoEleccion_validez de la elección de senadores por dicho principio y asignación a diversos partidos políticos las senadurías.",
    ];

    const tipoImpugnacion = datos.getters.getFormatoData.tipoImpugnacion;
    const ponente = datos.getters.getFormatoData.ponente;
    const TipoEleccion = datos.getters.getFormatoData.tipoEleccion;

    const nuevasColumnas = [
      `TipoInpugnacion_${tipoImpugnacion}`,
      `Ponente_${ponente}`,
      `TipoEleccion_${TipoEleccion}`
    ];

    const resultado = Array(columnasOneHot.length).fill(0);

    columnasOneHot.forEach((columna, index) => {
      //console.log("nuevasColumnas ", nuevasColumnas, "columna ", columna, " y ", nuevasColumnas.includes(columna))
      if (nuevasColumnas.includes(columna)) {
        resultado[index] = 1;
      }
    });
    
    return resultado;
  },

}