import { auth } from '../../firebase'

export default {
    verfiyEmail() {
        const url = 'https://electoraltech.com.mx/?email=' + auth.currentUser.email;
        auth.currentUser
            .sendEmailVerification({ url, handleCodeInApp: false })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            });
    }
}