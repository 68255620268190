<template>
  <div id="search" class="modal fade p-0">
    <div class="modal-dialog dialog-animated">
      <div class="modal-content h-100">
        <div class="modal-header" data-dismiss="modal">
          Inicio de sesión <i class="far fa-times-circle icon-close"></i>
        </div>
        <div class="modal-body">
          <form class="row" @submit.prevent="submit">
            <div class="col-12 align-self-center">
              <div class="row">
                <div class="col-12 pb-3">
                  <h2 class="search-title mb-3">Identifícate</h2>
                  <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent diam lacus, dapibus sed imperdiet consectetur.</p> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12 input-group">
                  <input
                    type="email"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Escribe tu correo"
                    :class="{ error: $v.form.correo.$error }"
                    v-model="$v.form.correo.$model"
                  />
                </div>
                <div class="col-12 input-group mt-3">
                  <input
                    type="password"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Escribe tu contraseña"
                    :class="{ error: $v.form.pass.$error }"
                    v-model="$v.form.pass.$model"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3" v-if="!$v.$invalid">
                  <div class="form-group">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                      :sitekey="key"
                      @expired="onExpired"
                    >
                    </vue-recaptcha>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 input-group align-self-center">
                  <button class="btn btn-bordered mt-3" :disabled="!captcha">
                    Iniciar sesión
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { mapMutations, mapState } from "vuex";
import { auth, fr, analytics, firebase } from "../../../firebase";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "inicioSesion",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      form: {
        correo: "",
        pass: "",
      },
      captcha: false,
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: //"http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
    };
  },
  validations: {
    form: {
      correo: {
        required,
        email,
      },
      pass: {
        required,
      },
    },
  },
  computed: {
    ...mapState("generals", ["key"]),
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
    }),
    onVerify: function (response) {
      if (response) {
        const url = process.env.NODE_ENV === "development" ? this.urlLocal : this.urlProd;
        fetch(url + "validateCaptcha/" + response)
          .then((res) => res.json())
          .then((res) => {
            console.info(res);
            this.captcha = true;
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    submit() {
      this.iniciarSesion();
    },
    cleanForm() {
      this.form.correo = "";
      this.form.pass = "";
      this.$v.form.$reset();
    },
    async iniciarSesion() {
      try {
        await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const res = await auth.signInWithEmailAndPassword(this.form.correo, this.form.pass);
        this.obtenerDatosUsuario(res.user.uid);

      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/wrong-password") {
          this.$swal({
            icon: "warning",
            title: "Usuario o contraseña incorrectos !",
          });
        } else {
          console.log("aqui");
          console.error(errorMessage);
          this.$swal({
            icon: "error",
            title: "Verifica tu conexión a internet e intenta de nuevo",
          });
        }
      }
    },
    obtenerDatosUsuario(uid) {
      fr.collection("usuarios")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const thisdata = doc.data();
            analytics.logEvent("Login exitoso", {
              correo: thisdata.correo, 
              nombre: thisdata.nombre, 
              eventCategory: thisdata.correo, 
              eventLabel: thisdata.nombre, 
            });
            console.log(doc.data());
            this.setUsuario(doc.data());
            window.$("#search").modal("hide");
            this.cleanForm();
            this.$swal({ icon: "success", title: "Bienvenido !" });
          } else {
            this.$swal({
              icon: "error",
              title: "Verifica tu usuario y contraseña",
            });
          }
        })
        .catch((err) => {
          console.log("Error en obtener datos", err);
          this.$swal({
            icon: "error",
            title: "Verifica tu conexión a internet e intenta de nuevo",
          });
        });
    },
  },
};
</script>

<style>
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>