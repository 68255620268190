export const routes = [
    {
        path: '/violenciaGenero',
        name: 'violencia',
        component: () => import(/* webpackChunkName: "ViolenciaGenero" */ '@/components/formats/gratis/ViolenciaGenero'),
        meta: { requiresAuth: false }
    },
    {
        path: '/denunciaViolencia',
        name: 'denuciaViolencia',
        component: () => import(/* webpackChunkName: "DenunciaViolenciaGenero" */ '@/components/formats/gratis/DenunciaViolencia'),
        meta: { requiresAuth: false }
    }, {
        path: '/solicitudInfo',
        name: 'solicitudInfo',
        component: () => import(/* webpackChunkName: "solicitudInfo" */ '@/components/formats/gratis/SolicitudInfo'),
        meta: { requiresAuth: false }
    },
    {
        path: '/apelacion',
        name: 'apelacion',
        component: () => import(/* webpackChunkName: "apelacion" */ '@/components/formats/pago/Apelacion'),
        meta: { requiresAuth: false }
    },
    {
        path: '/agentesMunicipales',
        name: 'agentesMunicipales',
        component: () => import(/* webpackChunkName: "agentesMunicipales" */ '@/components/formats/pago/agentesMunicipales'),
        meta: { requiresAuth: false }
    },
    {
        path: '/juicioDeRevision',
        name: 'juicioDeRevision',
        component: () => import(/* webpackChunkName: "juicioDeRevision" */ '@/components/formats/pago/juicioDeRevision'),
        meta: { requiresAuth: false }
    },
    {
        path: '/PromoJDC',
        name: 'PromoJDC',
        component: () => import(/* webpackChunkName: "promoJDC" */ '@/components/formats/gratis/PromoJDC'),
        meta: { requiresAuth: false }
    },
    {
        path: '/jdcCredencial',
        name: 'jdcCredencial',
        component: () => import(/* webpackChunkName: "jdcCredencial" */ '@/components/formats/gratis/jdcCredencial'),
        meta: { requiresAuth: false }
    },
    {
        path: '/recursoRIN',
        name: 'recursoRIN',
        component: () => import(/* webpackChunkName: "recursoRIN" */ '@/components/formats/pago/recursoRIN'),
        meta: { requiresAuth: false }
    },
    {
        path: '/PromoRIN',
        name: 'PromoRIN',
        component: () => import(/* webpackChunkName: "PromoRIN" */ '@/components/formats/gratis/PromoRIN'),
        meta: { requiresAuth: false }
    },
    {
        path: '/juicioJIN',
        name: 'juicioJIN',
        component: () => import(/* webpackChunkName: "JuicioRIN" */ '@/components/formats/pago/juicioJIN'),
        meta: { requiresAuth: false }
    },
    {
        path: '/tercerInteresado',
        name: 'tercerInteresado',
        component: () => import(/* webpackChunkName: "tercerInteresado" */ '@/components/formats/pago/tercerInteresado'),
        meta: { requiresAuth: false }
    },
    {
        path: '/recursoREP',
        name: 'recursoREP',
        component: () => import(/* webpackChunkName: "recursoREP" */ '@/components/formats/pago/recursoREP'),
        meta: { requiresAuth: false }
    },
    {
        path: '/juicioAF',
        name: 'juicioAF',
        component: () => import(/* webpackChunkName: "juicioAF" */ '@/components/formats/pago/juicioAF'),
        meta: { requiresAuth: false }
    },
    {
        path: '/recursoRAP',
        name: 'recursoRAP',
        component: () => import(/* webpackChunkName: "recursoRAP" */ '@/components/formats/pago/recursoRAP'),
        meta: { requiresAuth: false }
    },
    {
        path: '/recursoREC',
        name: 'recursoREC',
        component: () => import(/* webpackChunkName: "recursoRAP" */ '@/components/formats/pago/recursoREC'),
        meta: { requiresAuth: false }
    },
    {
        path: '/recursoRAP2',
        name: 'recursoRAP2',
        component: () => import(/* webpackChunkName: "recursoRAP" */ '@/components/formats/pago/recursoRAP2'),
        meta: { requiresAuth: false }
    },
    {
        path: '/recursoJDC2',
        name: 'recursoJDC2',
        component: () => import(/* webpackChunkName: "recursoRAP" */ '@/components/formats/pago/recursoJDC2'),
        meta: { requiresAuth: false }
    },
    

];